import http from '../utils/http.js'

const messageService = {

	/**	单张图片上传方法
	 * @param {Object} formData	formdata对象
	 */
	async imageUploadByOne(formData) {
		return new Promise((resolve, reject) => {
			http.post("/api/images/uploadImageOne", formData).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	查询指定tid, uid的笔记
	 * @param {Object} data	tid以及uid的对象
	 */
	async getMessageByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/message/getMessageByTid", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	查询指定id的笔记--新业务
	 * @param {Object} data	id
	 */
	async selectMessageById(data) {
		return new Promise((resolve, reject) => {
			http.get("/api/messageNew/selectMessageTreeNewById?id="+data.id).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	修改指定tid, uid的笔记
	 * @param {Object} data	笔记对象
	 */
	async updateMessageByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/message/updateMessageById", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	删除指定tid, uid的笔记
	 * @param {Object} data	笔记对象
	 */
	async deleteMessageByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/deleteMessageTree", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},

}

export default messageService;
