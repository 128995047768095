<template>
	<div id="PassNewParentDiv">
		<HeaderMenuComponent></HeaderMenuComponent>
		<!-- 占位样式 -->
		<div style="height: 5px;"></div>
		<div id="passNewBodeParentDiv">
			<div id="passNewBodyHeaderDiv">
				<div>
					<div style="width: 200px;">
						<el-input ref="myInput" v-model="input" placeholder="请输入检索内容后<回车>" clearable></el-input>
					</div>
					<div style="width: 100px;">
						<el-button type="primary" @click="insertClick">新增密码</el-button>
					</div>
				</div>
			</div>
			<!-- 占位样式 -->
			<div style="height: 5px;"></div>
			<div id="passNewBodyTableDiv">
				<div>
					<el-table :data="tableData" height="100%" border style="width: 100%">
						<el-table-column prop="title" label="标题" width="180">
						</el-table-column>
						<el-table-column prop="account" label="账号" width="220">
						</el-table-column>
						<el-table-column prop="mark" label="备注" width="320">
						</el-table-column>
						<el-table-column label="操作" width="180">
							<template slot-scope="scope">
								<el-button size="mini" style="margin: 5px;"
									@click="handleEdit(scope.$index, scope.row)">复制</el-button>
								<el-popconfirm title="这是一段内容确定删除吗？" @confirm="handleDelete(scope.$index, scope.row)">
									<el-button size="mini" type="danger" slot="reference">删除</el-button>
								</el-popconfirm>
							</template>
						</el-table-column>

					</el-table>
				</div>
			</div>
		</div>

		<!-- 弹出层样式--新增 -->
		<div>
			<!-- 新增密码业务模块 -->
			<el-card class=" box-cardPass" ref="insertPassRef">
				<div slot="header" class="clearfixPass">
					<span>卡片名称</span>
					<el-button style="float: right; padding: 3px 0" type="text" @click="passQuit">关闭</el-button>
				</div>
				<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" size="mini">
					<el-form-item label="标题">
						<el-input v-model="formLabelAlign.title"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="formLabelAlign.account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="formLabelAlign.pass"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="formLabelAlign.mark"></el-input>
					</el-form-item>
				</el-form>
				<div class="textPass itemPass">
					<!-- <div style="display: inline;position:relative;right: 20px;top: 20px;cursor: pointer;color: cyan;">123</div>
					<div style="display: inline;position:relative;left:20px;top: 20px;cursor: pointer;">456</div> -->
					<el-button style="float: right;margin: 0px 20px;" type="text" @click="insertButton('insert')">保存
					</el-button>
					<el-button style="float: right;margin: 0px 20px;" type="text" @click="insertButton('reset')">重置
					</el-button>
				</div>
			</el-card>

		</div>

	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import passService from '../service/pass.js'
	//密码存储模块-新
	export default {
		name: 'PassNew',
		data() {
			return {
				input: '', //输入框搜索内容
				// 表格内容
				tableData: [],
				tableDataBak: [], //表格数据备份
				labelPosition: 'right', //表单对齐方式--新增弹出层
				formLabelAlign: { //新增密码业务用的表单属性
					title: '',
					account: '',
					pass: '',
					mark: '',
					flag: '0',
					version: '0',
					createTime: '',
					updateTime: '',
					uid: ''
				},
				formLabelDataBack: { //新增密码业务用的表单属性备份
					title: '',
					account: '',
					pass: '',
					mark: '',
					flag: '0',
					version: '0',
					createTime: '',
					updateTime: '',
					uid: ''
				},
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {
			this.$loading({
				lock: true,
				text: '加载中',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			// 初始化元素高度
			jsUtils.PassParentInitTopHeight("passNewBodeParentDiv");
			jsUtils.PassParentInitTopHeight("passNewBodyTableDiv");

			// 初始查询
			await this.passSelect();
			this.passQuit();
			// 添加键盘按下事件监听器--搜索绑定
			document.addEventListener('keydown', this.keyEventListener);
			this.$refs.myInput.focus();

			this.$loading().close();
		},
		methods: {
			// 复制按钮事件
			handleEdit(index, row) {
				console.log(index, row);
				//老式复制,新版存在兼容性问题
				var textArea = document.createElement("textarea");
				textArea.value = row.pass;
				// 将文本区域添加到页面中
				document.body.appendChild(textArea);
				// 选择文本
				textArea.select();
				try {
					// 执行复制命令
					document.execCommand('copy');
					// console.log('文本已成功复制到粘贴板');
					this.$notify({
						title: '成功',
						message: '密码成功复制到粘贴板!',
						type: 'success',
						duration:1000
					});
				} catch (err) {
					// console.error('无法复制文本: ', err);
					this.$notify({
						title: '成功',
						message: '复制失败!\n' + err,
						type: 'error'
					});
				}
				// 从页面中移除文本区域
				document.body.removeChild(textArea);
			},
			//键盘监听
			keyEventListener(event) {
				if (event.keyCode === 13) {
					this.$loading({
						lock: true,
						text: '加载中',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					if (this.input == '') {
						this.tableData = [...this.tableDataBak];
						this.$loading().close();
						return;
					}
					const regex = new RegExp(this.input, "i"); // 使用关键词创建不区分大小写的正则表达式
					const arrList = this.tableData.filter(item => regex.test(item.title));
					// console.log(arrList);
					this.tableData = [...arrList];
					this.$loading().close();
				}
			},
			// 表格删除按钮事件
			async handleDelete(index, row) {
				this.$loading({
					lock: true,
					text: '加载中',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// console.log(index, row);
				await passService.deletePassData({
					id: row.id,
					uid: JSON.parse(window.localStorage.getItem("user")).uid
				}).then(resp => {
					// console.log(resp);
					if (resp) {
						if (resp.code === 200) {
							this.$message({
								message: '删除成功!',
								type: 'success'
							});
							this.passSelect();
						}
					}
				}).catch(err => {
					// console.log(err);
					this.$message({
						message: '删除失败!\n' + err,
						type: 'error'
					});
				});
				this.$loading().close();
			},
			//新增弹出层中关闭按钮事件
			passQuit() {
				this.$refs.insertPassRef.$el.style = "display:none;";
				this.$loading().close();
			},
			//新增按钮点击事件
			insertClick() {
				this.$loading({
					lock: true,
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.$refs.insertPassRef.$el.style =
					"position:absolute;z-index:9999; top: 50%;left: 50%;transform: translate(-50%, -50%);";
			},
			//新增密码业务卡片中按钮事件
			async insertButton(str) {
				// console.log('点击',str);
				if (str === 'insert') {
					this.$refs.insertPassRef.$el.style =
						"position:absolute;z-index:1; top: 50%;left: 50%;transform: translate(-50%, -50%);";
					//console.log('loadingBody>>', this.loadingBody);
					let that = this;
					let myDate = new Date();
					let time = myDate.toLocaleString();
					this.formLabelAlign.createTime = time;
					this.formLabelAlign.updateTime = time;
					this.formLabelAlign.uid = JSON.parse(window.localStorage.getItem("user")).uid;
					// console.log('表单数据>>', this.formLabelAlign);
					await passService.insertPassData(this.formLabelAlign).then(resp => {
						//console.log("新增结果>>", resp);
						if (resp.code === 200) {
							this.formLabelAlign = Object.assign({}, this.formLabelDataBack); //	重置表单
							/* //初始化查询密码数据
							this.selectPass().then(resp => {
								//console.log(resp);
								if (resp.code === 200) {
									let passList = resp.data.result;
									passList.forEach((item) => {
										let passString = {
											passString: '*********'
										}
										item = Object.assign(item, passString);
										//console.log(item);
									});
									this.tableData = passList;
								}
							}).catch(err => {
								console.log(err);
							}); */
							this.$message({
								message: '新增成功!',
								type: 'success'
							});
							this.passSelect();
						}
					}).catch(err => {
						console.log("新增报错>>", err);
						this.$message({
							message: '新增失败!' + '\n' + err,
							type: 'err'
						});
					}).finally(() => {
						this.passQuit();
					});
					//console.log('新增执行完毕');
				}

				if (str === 'reset') {
					// console.log("reset");
					this.formLabelAlign = Object.assign({}, this.formLabelDataBack);
					// console.log("重置表单>>", this.formLabelAlign);
				}
			},
			// 查询密码数据方法
			async passSelect() {
				await passService.selectPassData().then(resp => {
					if (resp) {
						if (resp.code === 200) {
							// console.log(resp.data.result);
							this.tableData = resp.data.result;
							this.tableDataBak = [...this.tableData];
							this.$message({
								message: '查询成功!',
								type: 'success'
							});
						}
					}
				}).catch(err => {
					// console.log("异常");
					this.$message({
						message: '查询失败!',
						type: 'error'
					});
				})
			}
		},
		beforeDestroy() {
			// console.log("销毁前调用");
			// 添加键盘按下事件监听器--搜索绑定
			document.removeEventListener('keydown', this.keyEventListener);
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>
	#passNewBodeParentDiv {}

	/* 主体中表格父级div */
	#passNewBodyTableDiv {
		display: flex;
		justify-content: center;
	}

	#passNewBodyTableDiv>div {
		width: 920px;
		height: 100%;
		overflow: auto;
	}

	/* 搜索区域父级div */
	#passNewBodyHeaderDiv {
		display: flex;
		justify-content: center;
	}

	#passNewBodyHeaderDiv>div {
		width: 920px;
		display: flex;
	}
</style>