const jsUtils = {

	/**	设置指定元素高度为当前可视化区域高度
	 * @param {Object} elementId	要设置的元素id
	 */
	PassParentInit(elementId) {
		let heightBody = document.documentElement.clientHeight;
		// console.log("可视区窗口高度:" + heightBody) // 可视区窗口高度
		document.getElementById(elementId).style.height = heightBody + "px";
	},

	/** 设置指定元素高度为可视化区域内当前元素顶部坐标下全高度
	 * @param {Object} elementId	要设置元素的id
	 */
	async PassParentInitTopHeight(elementId) {
		let heightBody = document.documentElement.clientHeight;
		// console.log("可视区窗口高度:" + heightBody) // 可视区窗口高度
		let heightNum = document.getElementById(elementId).offsetTop;
		// console.log("当前元素与页面顶部距离", heightNum) // 当前元素与页面顶部距离
		document.getElementById(elementId).style.height = heightBody - heightNum + "px";
	},
	
	/** 设置指定元素高度为可视化区域内当前元素顶部坐标下全高度--减去顶部搜索框大小--高度写死为60px
	 * @param {Object} elementId	要设置元素的id
	 */
	async PassParentInitTopHeightOrsearchBody(elementId) {
		// console.log('改变元素大小');
		let heightBody = document.documentElement.clientHeight;
		// console.log("可视区窗口高度:" + heightBody) // 可视区窗口高度
		let heightNum = document.getElementById(elementId).offsetTop;
		// console.log("当前元素与页面顶部距离", heightNum) // 当前元素与页面顶部距离
		document.getElementById(elementId).style.height = heightBody - heightNum - 60 + "px";
	},
	
	/** 设置指定元素宽度为可视化区域内当前元素左边坐标下全宽度
	 * @param {Object} elementId	要设置元素的id
	 */
	PassParentInitLeftHeight(elementId) {
		let heightBody = document.documentElement.clientWidth;
		// console.log("可视区窗口宽:" + heightBody) // 可视区窗口高度
		let heightNum = document.getElementById(elementId).offsetLeft;
		// console.log("当前元素与页面左边距离", heightNum) // 当前元素与页面顶部距离
		// 在减上20是为了让出滚动条位置
		document.getElementById(elementId).style.width = heightBody - heightNum -20 + "px";
	},
	
	/**获取指定ID的元素到浏览器左边的位置
	 * @param {Object} elementId	元素的ID
	 */
	elementLeftPX(elementId){
		// 获取指定 ID 的元素
		var element = document.getElementById(elementId);
		// 获取元素的位置信息
		var rect = element.getBoundingClientRect();
		// 获取相对于视口左边的位置
		var leftPosition = rect.left;
		// console.log('元素相对于视口左边的位置：', leftPosition);
		return {
			"leftPx":leftPosition
		};
	},
	
	/**
	 * 获取鼠标位置
	 */
	mouseXOrY() {
		var e = event || window.event;
		var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
		var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
		var x = e.pageX || e.clientX + scrollX;
		var y = e.pageY || e.clientY + scrollY;
		// console.log("鼠标位置>>",'x: ' + x + ',y: ' + y);
		return {
			'x': x,
			'y': y
		};
	},

	/**	设置全局遮罩的显示或隐藏
	 * @param {Object} bool	传入true表示隐藏反之显示
	 * @param {Object} elementId	大遮罩元素的id
	 */
	loadingAllIsNone(bool, elementId) {
		if (bool) {
			document.getElementById(elementId).style = "display: none;";
			// console.log("进入隐藏遮罩");
		} else {
			// console.log("进入显示遮罩");
			document.getElementById(elementId).style = "display: block;";;
		}
	},

	/** 
	 * 获取当前登录用户的信息返回user对象
	 */
	getLoginUser() {
		try {
			if (window.localStorage.getItem("user") != null) {
				let user = JSON.parse(window.localStorage.getItem("user"));
				return user;
			}
			return null;
		} catch (err) {
			return null;
		}
	},
	
	/** 
	 * 获取当前时间 年月日时分秒
	 */
	getDateTime() {
		try {
			let myDate = new Date();
			let time = myDate.toLocaleString( ); 		//当前时间
			return time+"";
		} catch (err) {
			return null;
		}
	},
	
	/**
	 * 取消浏览器的默认右键事件
	 */
	mouseOut() {
		// 取消浏览器自带的右键菜单
		window.oncontextmenu = function(e) {
			//取消默认的浏览器自带右键
			e.preventDefault();
		}
	},

	/**设置元素高度为顶部到页面底部的距离
	 * @param {Object} elementId 要设置元素高度的元素id
	 */
	async elementHeightOnDoc(elementId){
		// 获取要测量的元素
		const element = document.getElementById(elementId);
		// 获取元素的位置信息
		const rect = element.getBoundingClientRect();
		// 计算元素顶端到页面底端的距离
		const distanceToBottom = window.innerHeight - rect.top;
		return distanceToBottom;
		//this.tableHeight = distanceToBottom;
		// console.log('距离为>>', this.tableHeight);
	},

	/** 下雨效果
	 * @param {Object} elementId	传入空div的id
	 */
	yu(elementId) {
		const box = document.getElementById(elementId);
		let boxHeight = box.clientHeight;
		let boxWidth = box.clientWidth;
		// 页面大小发生变化时，改变盒子大小
		window.onresize = function() {
			boxHeight = box.clientHeight;
			boxWidth = box.clientWidth;
		}
		// 每隔一段时间,添加雨滴
		setInterval(() => {
			const rain = document.createElement('div');
			rain.classList.add('rain');
			rain.style.top = 0;
			// 随机刷新雨点位置
			rain.style.left = Math.random() * boxWidth + 'px';
			// 随机雨点透明度
			rain.style.opacity = Math.random();
			box.appendChild(rain);
			// 每隔一段时间,雨水下落
			let race = 1;
			const timer = setInterval(() => {
				// 判断“雨滴”元素的top属性是否超出“盒子”元素的高度来决定是否停止动画
				if (parseInt(rain.style.top) > boxHeight) {
					clearInterval(timer);
					box.removeChild(rain);
				}
				// 每次定时器执行时，“雨滴”元素的top值会逐渐增加，
				//并且增加的速率会随着时间的推移而逐渐加快
				race++;
				rain.style.top = parseInt(rain.style.top) + race + 'px'
			}, 20)
		}, 50);
	},
}

export default jsUtils;
