<template>
	<div id="messageParent">
		<div id="leftDivMessageShow">
			<el-tree style="margin-top: 20px;" :data="data" node-key="id" :default-checked-keys="[1]"
				:expand-on-click-node="false" :props="defaultProps" @node-click="handleNodeClick"
				:highlight-current="true" :default-expanded-keys="showKeys"></el-tree>
		</div>
		<!-- <div id="message" v-html="messageData.htmlText"></div> -->
		<div id="message">
			<v-md-editor ref="editor" v-model="messageData.text" :include-level="[1,2,3,4,5,6]" mode='preview'
				@copy-code-success="handleCopyCodeSuccess" :default-show-toc="1==1" left-toolbar="undo"
				right-toolbar="preview toc sync-scroll fullscreen">
			</v-md-editor>
		</div>
		<div id="rainBox"></div>
	</div>
</template>

<script>
	import messageService from '../service/Message.js'
	import jsUtils from '../utils/jsUtils.js'
	import messageTreeService from '../service/MessageTree.js'

	export default {
		name: '',
		data() {
			return {
				messageTid: 0, //	要展示的笔记tid
				messageData: {}, //笔记内容
				data: [],
				defaultProps: {
					children: 'messageTreeList',
					label: 'title'
				},
				showKeys: [1], //默认展开的树菜单id
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {

			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			// console.log("调用mounted");
			this.messageTid = this.$route.query.tid; //从地址中获取tid
			//初始化页面大小
			jsUtils.PassParentInitTopHeight("messageParent");
			await this.selectByTid();
			jsUtils.yu("rainBox"); //下雨效果

			// 查询属性菜单
			// console.log("查询菜单");
			await this.selectMessageTreeData();

			// this.$refs.editor.$el.style = "background-color: rgba(100, 100, 100, 0.6);";
			// console.log(document.getElementsByClassName("vuepress-markdown-body")[0]);
			//改变Markdown编辑器内部的背景色
			document.getElementsByClassName("github-markdown-body")[0].style =
				"background-color: rgba(200, 200, 200, 0.6);";

			jsUtils.loadingAllIsNone(true, "loadingAll"); //隐藏大遮罩

			this.$loading().close();
		},
		methods: {
			//粘贴代码块
			handleCopyCodeSuccess(code) {
				console.log(code);
			},
			//查询树形菜单数据
			async selectMessageTreeData() {
				let that = this;
				let user = jsUtils.getLoginUser();
				// console.log("message>>", that.messageData);
				await messageTreeService.getMessageTreeDataByTid({
					id: that.messageTid,
					uid: user.uid
				}).then(resp => {
					// console.log("结果为>>", resp);
					if (resp.code === 200) {
						that.data = resp.data.result;
						this.showKeys = [resp.data.result[0].id]; //添加默认展开项
					}
				}).catch(err => {
					console.log("查询出错>>", err);
				});
			},
			// 树形菜单点击事件
			async handleNodeClick(data) {
				// console.log(data);
				this.messageTid = data.id;
				await this.selectByTid();
			},
			// 查询指定笔记
			async selectByTid() {
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				let user = jsUtils.getLoginUser();
				let uid = 0;
				let that = this;
				if (user != null) {
					uid = user.uid;
				}
				await messageService.getMessageByTid({
					tid: this.messageTid,
					uid: uid
				}).then(resp => {
					// console.log("查询结果>>", resp);
					if (resp.code === 200) {
						that.messageData = resp.data.result[0];
					}
				}).catch(err => {
					console.log("查询报错>>", err);
				});

				this.$loading().close();
			}
		},
		beforeDestroy() {
			// console.log("销毁前调用");
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>
	.vuepress-markdown-body {
		/* background-color: black; */
	}

	#messageParent {
		position: relative;
		width: 100%;
		/* background-color: rgba(100, 100, 100, 0.4); */
		/* background: url('~@/assets/a2.jpeg') no-repeat center; */
		/* background-size: 100% 100%; */
		/* opacity: 0.7; */
		/* height: 100%; */
	}

	/* 左侧标题栏 */
	#leftDivMessageShow {
		width: 360px;
		height: 100%;
		/* display: inline; */
		float: left;
		/* padding-top: 20px; */
		padding-left: 10px;
	}

	/* 笔记内容区域 */
	#message {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%);
		/* background-color: rgba(100, 100, 100, 0.6); */
		width: 1050px;
		height: 100%;
	}
</style>
