import http from '../utils/http.js'

//笔记评论
const MessageCommentNew = {
	/**新增笔记评论
	 * @param {Object} data
	 */
	async insertMessageCommentNew(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageNew/insertMessageComment", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	
}


export default MessageCommentNew;
