<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="BillReportFormsParentDiv">
			<div>
				<div style="display: inline-block;">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
						value-format="yyyy-MM-dd" end-placeholder="结束日期">
					</el-date-picker>
				</div>
				<div style="display: inline-block;margin-left: 10px;">
					<el-button type="primary" size="small" @click="selectClick">查询</el-button>
				</div>
				<div style="width: 120px;display: inline-block;">
					<download-excel class="export-excel-wrapper" :data="json_data" :fields="json_fields"
						name="记账日期分类汇总统计.xls">
						<!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
						<el-button type="success" size="small">导出EXCEL</el-button>
					</download-excel>
				</div>
			</div>
			<div id="BillReportFormsTableDiv">
				<el-table :data="tableDataShow" stripe style="width: 1080px; margin: auto; overflow: auto;"
					:height="tableHeight">
					<el-table-column prop="日期" label="日期" width="180">
					</el-table-column>
					<el-table-column prop="物业家居" label="物业家居" width="180">
					</el-table-column>
					<el-table-column prop="交通出行" label="交通出行" width="180">
					</el-table-column>
					<el-table-column prop="饮食就餐" label="饮食就餐" width="180">
					</el-table-column>
					<el-table-column prop="购物娱乐" label="购物娱乐" width="180">
					</el-table-column>
					<el-table-column prop="其他类型" label="其他类型" width="180">
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import VueJsonExcel from 'vue-json-excel';
	import billService from '../../service/billService.js'
	import jsUtils from '../../utils/jsUtils.js'

	export default {
		name: 'BillReportForms',
		data() {
			return {
				beginTime: '2024-01-01', //查询起始时间属性
				endTime: '2024-01-31', //查询终止时间属性
				tableDataShow: [], //展示用表格数据属性
				value1: '', //日期选择绑定值
				tableHeight: '', //表格高度
				tableData: [], //表格数据
				// 表格导出格式属性
				json_fields: {
					"日期": "日期",
					"交通出行": "交通出行",
					"物业家居": "物业家居",
					"饮食就餐": "饮食就餐",
					"购物娱乐": "购物娱乐",
					"其他类型": "其他类型",
				},
				// 表格导出数据源属性
				json_data: [],
				// 表格导出所需属性
				json_meta: [
					[{
						" key ": " charset ",
						" value ": " utf- 8 "
					}]
				]
			}
		},
		components: {
			VueJsonExcel,
		},
		created() {
			// console.log("触发created");
		},
		async mounted() {
			// console.log("调用mounted");
			//设置主体div整体高度
			this.$loading({
				text: '加载中'
			});
			this.divWH();
			// await jsUtils.PassParentInitTopHeight("BillReportFormsParentDiv"); //大主体DIV
			if (this.value1 == '') {
				this.beginTime = this.selectDay();
				this.endTime = this.selectDay();
			}
			await this.selectData();
			this.$loading().close();
		},
		methods: {
			//获取当天yyyy-MM-dd格式日期
			selectDay() {
				// 创建 Date 对象
				const today = new Date();
				// 获取年、月、日
				const year = today.getFullYear();
				const month = ('0' + (today.getMonth() + 1)).slice(-2); // 月份从 0 开始，因此需要加 1，并且保证两位数
				const day = ('0' + today.getDate()).slice(-2); // 保证两位数
				// 格式化日期
				const formattedDate = `${year}-${month}-${day}`;
				return formattedDate;
			},
			// 查询按钮点击事件
			async selectClick() {
				this.$loading({
					text: '加载中'
				});
				if (this.value1 == '') {
					// console.log('数据', this.value1);
					this.beginTime = this.selectDay();
					this.endTime = this.selectDay();
				}else{
					// console.log("日期>>", this.value1);
					this.beginTime = this.value1[0];
					this.endTime = this.value1[1];
				}
				await this.selectData();
				this.$loading().close();
			},
			//设置表格高度
			divWH() {
				let heightBody = document.documentElement.clientHeight;
				let heightNum = document.getElementById("BillReportFormsTableDiv").offsetTop;
				this.tableHeight = (heightBody - heightNum) + "px";
				//console.log("123>>", this.tableHeight);
			},
			//查询数据方法
			async selectData() {
				await billService.getBillReportFormsDateType({
					"queryTimeBegin": this.beginTime,
					"queryTimeEnd": this.endTime
				}).then(resp => {
					// console.log("执行成功>>", resp);
					if (resp.code === 200) {
						this.tableData = resp.data.result;
						this.json_data = this.tableData;
						this.tableDataShow = this.tableData;
						this.tableData.forEach(Item => {
							// console.log("Item>>", Item.日期);
							Item.日期 = this.DataFormatted(Item.日期);
						});
					}
				}).catch(err => {
					console.log("执行失败>>", err);
				});
			},
			// 将时间戳格式转换为yyyy-MM-dd
			DataFormatted(dateY) {
				// 输入时间戳
				const timestamp = dateY;
				// 创建 Date 对象
				const date = new Date(timestamp);
				// 提取年、月、日
				const year = date.getFullYear();
				const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份从 0 开始，因此需要加 1，并且保证两位数
				const day = ('0' + date.getDate()).slice(-2); // 保证两位数
				// 格式化日期
				const formattedDate = `${year}-${month}-${day}`;
				// console.log(formattedDate); // 输出: "2024-02-06"
				if (dateY == '-62135798400000') {
					return '合计';
				}
				return formattedDate;
			},
			// 表格导出触发方法
			exportExcel() {
				// 导出 Excel 文件的逻辑
				console.log("触发表格导出>>");
			},
		},
		beforeDestroy() {
			// console.log("销毁前调用");
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>
</style>
