<template>
	<div id="body">
		<!-- 布局容器 -->
		<el-container>
			<!-- 头部区域 -->
			<el-header style="z-index: 1;">
				<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					id="tou">
					<el-submenu index="2">
						<template slot="title">我的工作台</template>
						<el-menu-item index="2-1">发布笔记</el-menu-item>
						<el-menu-item index="2-2" :disabled='disabledPass'>密码存储-旧</el-menu-item>
						<el-menu-item index="2-3">选项3</el-menu-item>
						<el-submenu index="2-4" :disabled='disabledBill'>
							<template slot="title">账本相关</template>
							<el-menu-item index="2-4-1">记账日期分类汇总统计</el-menu-item>
							<el-menu-item index="2-4-2">记账日期分类明细统计</el-menu-item>
							<el-menu-item index="BillReportFormsDataDetail">记账日期明细统计</el-menu-item>
						</el-submenu>
					</el-submenu>
					<el-menu-item index="3">发布笔记</el-menu-item>
					<el-menu-item index="7">查看笔记</el-menu-item>
					<el-menu-item index="1">密码存储</el-menu-item>
					<el-menu-item index="4" :disabled='disabledBill'><a>账单管理</a></el-menu-item>
					<el-menu-item index="test"><a>测试模块</a></el-menu-item>
					<el-menu-item index="5" id="userBall"><a>{{userMenuText}}</a></el-menu-item>
				</el-menu>
			</el-header>

			<!-- 主体区域 -->
			<el-main id="body_el">
				<!-- 密码业务模块 -->
				<PassComponent v-if="activeIndex1=='2-2'"></PassComponent>
				<Message v-if="activeIndex1=='2-1'"></Message>
				<ZhangBen v-if="activeIndex1=='4'"></ZhangBen>
				<MessageTree v-if="activeIndex1=='3'"></MessageTree>
				<!-- <testVue v-if="activeIndex1=='6'"></testVue> -->
				<MessageTreeNew v-if="activeIndex1=='7'"></MessageTreeNew>
				<PassNewComponent v-if="activeIndex1=='1'"></PassNewComponent>
				<BillReportForms v-if="activeIndex1=='2-4-1'"></BillReportForms>
				<UserComponent v-if="activeIndex1=='5'"></UserComponent>
			</el-main>
		</el-container>

	</div>
</template>

<script>
	import PassComponent from '../components/PassComponent.vue'
	import ZhangBen from '../components/ZhangBen.vue'
	import Message from '../components/Message.vue'
	import MessageTree from '../components/MessageTree.vue'
	import testVue from '../components/test.vue'
	import MessageTreeNew from '../components/MessageTreeNew.vue'
	import LoginService from '../service/login.js'
	import PassNewComponent from '../components/PassNewComponent.vue'
	import BillReportForms from '../components/BillComponent/BillReportForms.vue'
	import UserComponent from '../components/UserComponent.vue'

	export default {
		name: 'index',
		data() {
			return {
				activeIndex: '7',				// 顶部选项卡默认选中哪一个
				activeIndex1:"7",				//顶部选项卡选中后改变
				user:{}		,//登录用户的信息
				disabledPass:false		,//密码模块是否可用,true表示未登录隐藏
				disabledBill:false		,//账单模块是否可用,true表示未登录隐藏
				userMenuText:'个人中心'	,//个人中心菜单的显示标题
				isLogin:true			//是否登录, true表示已登录
			};
		},
		components: {
			PassComponent,
			ZhangBen,
			Message,
			MessageTree,
			testVue,
			MessageTreeNew,
			PassNewComponent,
			BillReportForms,
			UserComponent
		},
		created() {
			// console.log("触发created");
			//初始化
			this.activeIndex1="7";		//默认渲染的模块
			// 取消浏览器自带的右键菜单
			window.oncontextmenu = function(e) {
				//取消默认的浏览器自带右键
				e.preventDefault();
			}
			document.title='笔记';
		},
		async mounted() {
			// console.log("调用mounted");
			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.user=window.localStorage.getItem("user");
			await LoginService.LoginOrNot().then(resp=>{
				// console.log("是否登录>>",resp);
				if(resp.code!=200){		//表示未登录
					this.disabledPass=true ;		//密码模块
					this.disabledBill=true;			//账单模块
					this.userMenuText='登录' ;
					this.isLogin=false;
				}else{
					this.userMenuText=JSON.parse(window.localStorage.getItem("user")).username;
				}
			}).catch(err=>{
				console.log("报错>>",err);
			});
			
			this.$loading().close();
		},
		methods: {
			// 顶部选项卡的点击选择触发事件
			handleSelect(key, keyPath) {
				// console.log("点击菜单的index:" + key, "点击菜单的层级:" + keyPath);
				if(key==='5' && !this.isLogin){
					// console.log("进入");
					window.localStorage.clear();
					this.$router.push("/");
					return false;
				}
				if(key==='6'){
					this.$router.push({name: "testComponent",params: {index:6}});
					window.localStorage.setItem('menuIndex',6);
					return false;
				}
				this.activeIndex1=key;
			}
		}
	}
</script>

<style>
	#body {
		/* border: 1px solid red; */
		/* background-color: aqua; */
	}

	#userBall {
		/* border: 1px solid red; */
		right: 1%;
		position: absolute;
	}

	#tou {
		/* border: 1px solid blue; */
		position: relative;
	}

	/* 主体模块区域 */
	#body_el {
		padding: 0px 20px;
		/* border: 1px solid blue; */
	}
</style>
