import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

//引入elementui组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//使用elementui
Vue.use(ElementUI);
import {
	Message
} from 'element-ui';

import HeaderMenuComponent from './components/HeaderMenuComponent.vue';
Vue.component('HeaderMenuComponent',HeaderMenuComponent);

// Markdown编辑器组件
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';
VueMarkdownEditor.use(vuepressTheme, {
	Prism,
});
VueMarkdownEditor.use(createAlignPlugin());
Vue.use(VueMarkdownEditor);

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// 引入所有语言包
// import hljs from 'highlight.js';
// VueMarkdownEditor.use(githubTheme, {
//   Hljs: hljs,
// });
// highlightjs 核心代码
import hljs from 'highlight.js/lib/core';
// 按需引入语言包
import sql from 'highlight.js/lib/languages/sql';
import json from 'highlight.js/lib/languages/json';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('json', json);
hljs.registerLanguage('java', java);
hljs.registerLanguage('javascript', javascript);
VueMarkdownEditor.use(githubTheme, {
	Hljs: hljs,
});

//代码块快捷粘贴
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
VueMarkdownEditor.use(createCopyCodePlugin());

import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import createCopyCodePreview from '@kangc/v-md-editor/lib/plugins/copy-code/preview';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
VMdPreviewHtml.use(createCopyCodePreview());

// vxetable前端UI框架
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)

// ...右键菜单组件
// import VXETable from 'vxe-table'
import VXETablePluginMenus from 'vxe-table-plugin-menus'
// ...
VXETable.use(VXETablePluginMenus)

// vue-json-excel表格导出插件
import JsonExcel from 'vue-json-excel';
Vue.component('downloadExcel', JsonExcel);

import jsUtils from './utils/jsUtils.js'
import userLogin from './service/login.js'



//全局前置守卫
router.beforeEach((to, from, next) => {
	// console.log("======================全局前置守卫内======================");
	// console.log("从哪来:" + from.path, "到哪去:" + to.path);
	jsUtils.loadingAllIsNone(true,'loadingAll');
	//没登录还要访问user路径就跳转登录
	if(to.path=='/user'){
		userLogin.LoginOrNot().then(resp=>{
			if(resp.code===201){
				router.push({path:'/login'});
			}
		});
	}
	//暂时不做必须登录的限制
	next(); //放行
	// let user = window.localStorage.getItem("user");
	// let jwt = window.localStorage.getItem("jwt");
	// console.log("登录用户信息>>", user, "登录令牌>>", jwt);
	// console.log();
	// 未登录的统一登录

	/*
	if ((jwt === null || user === null) && to.path != "/") {
		window.localStorage.clear();
		Message.warning({
			message: "请登录后再操作!",
			type: 'warning',
			duration: 2000,
			showClose: true
		});
		router.push("/");
	} else {
		next(); //放行
	}
	*/
});

// 取消浏览器自带的右键菜单
// window.oncontextmenu = function(e) {
// 	//取消默认的浏览器自带右键
// 	e.preventDefault();
// }

new Vue({
	router,
	el: '#app',
	render: h => h(App)
}).$mount('#app')
