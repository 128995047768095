<template>
	<div id="messageBody" ref="messageRef" v-loading.fullscreen.lock="fullscreenLoading">
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="leftDiv">
			<div>
				<el-input v-model="messageTreeInput" placeholder="请输入内容">
					<el-button slot="append" icon="el-icon-search" @click="inputClick"></el-button>
				</el-input>
			</div>
			<div id="leftDivChertDiv">
				<el-tree ref="orgTree" :data="data" node-key="id" :expand-on-click-node="false" :props="defaultProps"
					@node-click="handleNodeClick" :highlight-current="true" :default-checked-keys="dataShowList">
					<!-- 树节点的操作按钮 -->
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<span>{{ node.label }}</span>
						<span>
							<el-button type="text" size="mini" @click="() => append(data)">
								添加
							</el-button>
							<el-button type="text" size="mini" @click="() => remove(node, data)">
								调整
							</el-button>
						</span>
					</span>
				</el-tree>
			</div>
		</div>

		<!-- 文件上传 -->
		<div id="uploadMessageUpdateDivParent">
			<el-upload class="upload-demo" action="/api/images/uploadMessageTreeDocOne" :headers='uploadHeader'
				:on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" :limit="1"
				name='uploadFile' :on-exceed="handleExceed" :file-list="fileList" :on-success='uploadOk'>
				<el-button size="small" type="primary">文件上传</el-button>
				<!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
			</el-upload>
		</div>
		<!-- 新增笔记标题的弹出层 -->
		<div id="cardDiv">
			<el-card class="box-cardMessage" shadow="always">
				<!-- <h4>笔记标题</h4> -->
				<el-input v-model="input" placeholder="请输入标题"></el-input>
				<el-button style="margin-top: 10px;" type="primary" @click="insertMessage" round>新增</el-button>
				<el-button style="margin-top: 10px;" type="primary" @click="downClick" round>取消</el-button>
			</el-card>
		</div>
		<!-- 调整笔记标题的弹出层 -->
		<div id="cardDivUpdate">
			<el-card class="box-cardMessage" shadow="always">
				<el-form label-position="right" label-width="80px" :model="formLabelAlign" size="mini">
					<el-form-item label="笔记标题">
						<el-input v-model="formLabelAlign.title"></el-input>
					</el-form-item>
					<el-form-item label="笔记备注">
						<el-input v-model="formLabelAlign.bz"></el-input>
					</el-form-item>
					<el-form-item label="笔记次序">
						<!-- <el-input v-model="formLabelAlign.orderId"></el-input> -->
						<!-- <el-select ref="selectInput" v-model="formLabelAlign.orderId" placeholder="请选择">
							<el-option v-for="item in order_id_list" :key="item.value" :label="item.value"
								:value="item.value">
							</el-option>
						</el-select> -->
						<el-input-number ref="selectInput" v-model="formLabelAlign.orderId" :min="1" :max="10"
							label="笔记层级">
						</el-input-number>
					</el-form-item>
				</el-form>
				<el-button size="small" style="margin-top: 10px;" type="danger" @click="deleteMessageTree" round>删除笔记
				</el-button>
				<el-button size="small" style="margin-top: 10px;margin-left: 30px;" type="primary"
					@click="savaClickMessageTree" round>保存</el-button>
				<el-button size="small" style="margin-top: 10px;margin-left: 30px;" type="primary" @click="downClick"
					round>取消</el-button>
			</el-card>
		</div>
		<div id="rightDiv">
			<v-md-editor v-model="text" @upload-image="uploadImage" @save="mdSava" :disabled-menus="[]"
				:include-level="[1,2,3,4,5,6]">
			</v-md-editor>
		</div>
	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import http from '../utils/http.js'
	import messageService from '../service/Message.js'
	import messageTreeService from '../service/MessageTree.js'

	export default {
		name: 'message',
		components: {},
		created() {
			// console.log("触发created");
		},
		data() {
			return {
				text: '', //要解析的Markdown文本
				tid: 0,
				tidAll: 0, //最初进来的一级标题tid备份
				uid: 0,
				messageData: null, //笔记对象
				data: [], //树菜单绑定数据
				dataBak: [], //树菜单绑定数据备份
				dataShowList: [23], //树菜单节点默认展开的key数据
				dataShowListBak: [1], //树菜单节点默认展开的key数据备份
				defaultProps: {
					children: 'messageTreeList',
					label: 'title'
				},
				input: '', //标题新增输入框
				insertParentData: {}, //新增时触发事件的数据行
				fullscreenLoading: false, //全屏遮罩打开与否
				updateLogo: false, //笔记修改单击事件触发标识, 与查询区分, 真标识正在执行修改
				insertLogo: false, //笔记新增单击事件触发标识, 与查询区分, 真标识正在执行新增
				formLabelAlign: { //表单属性
					title: '',
					bz: '',
					orderId: '',
					parentid: -1
				},
				order_id_list: [], //层级选择器属性
				messageTreeInput: '', //搜索框绑定属性
				fileList: [], //文件上传的文件列表
				uploadHeader: {}, //文件上传请求头
			}
		},
		created() {
			// 
		},
		async mounted() {

			// jsUtils.loadingAllIsNone(false,"loadingAll");
			// console.log("触发初始化方法>>");
			//关闭默认的全局遮罩
			jsUtils.loadingAllIsNone(true, "loadingAll");

			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			this.$refs.selectInput.$el.style = "width:100%;"; //设置层级选择器的宽度

			// 隐藏卡片弹出层
			document.getElementById("cardDiv").style = "display:none;";

			// console.log("调用mounted");
			// 鼠标事件
			document.getElementById("messageBody").onmousedown = function(e) {
				let element = e.srcElement;
				// console.log("事件>>", e, element);
				if (e.button == 2) {
					// console.log("你点了右键");
				} else if (e.button == 0) {
					// console.log("你点了左键");
				} else if (e.button == 1) {
					// console.log("你点了滚轮");
				}
			}

			//设置父级div高度
			jsUtils.PassParentInitTopHeightOrsearchBody("messageBody");
			// 设置编辑栏的宽度
			jsUtils.PassParentInitTopHeightOrsearchBody("rightDiv");
			jsUtils.PassParentInitLeftHeight("rightDiv");

			//获取tid
			this.tid = this.$route.query.tid;
			this.tidAll = this.tid;
			// console.log("tid>>", this.tid, this.tid == null);
			//获取uid
			let user = jsUtils.getLoginUser();
			if (user != null) {
				this.uid = user.uid;
			}
			//tid有值就查询内容
			if (this.tid != null) {
				// console.log("查询笔记");
				await this.selectByTid();
			}

			// 查询属性菜单
			// console.log("查询菜单");
			await this.selectMessageTreeData();

			//初始化文件上传请求头
			let jwt = window.localStorage.getItem("jwt");
			this.uploadHeader = {
				"jwt": jwt
			};

			//初始化页面大小
			jsUtils.PassParentInitTopHeight("messageBody");

			await this.$loading().close();
		},
		methods: {
			// 上传成功函数
			uploadOk(response, file, fileList) {
				// console.log('上传完毕', response, file, fileList);
				if (response.code === 200) {
					this.fileList = [];
					this.text = this.text + '[' + file.name + '](' + response.data.result + ')';
				}
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			//迭代添加搜索数据集合的方法
			dataListShow(dataList, data, input, mark) {
				// console.log("目标list>>",data);
				if (!Array.isArray(data)) {
					// console.log("不是集合");
					let dataBak = data;
					data = [];
					// console.log("改变后>>",data);
					data.push(dataBak);
				}
				data.forEach((item, index) => {
					// console.log("遍历>>",item,'index>>',index,'子级>>',item.messageTreeList.length);
					if (item.messageTreeList.length > 0) {
						// console.log("有子级>>",item.messageTreeList);
						this.dataListShow(dataList, item.messageTreeList, input, mark);
					} else if (item.messageTreeList.length = 0 && !item.title.includes(input)) {
						//
					} else {
						if (item.title.includes(input)) {
							// console.log("包含目标>>",item);
							item.messageTreeList = [];
							dataList.push(item);
						}
					}
				});
			},
			//搜索栏单击事件--搜索功能
			inputClick() {
				this.$loading({
					title: '加载中'
				});
				//先还原初始数据
				this.data = [...this.dataBak];
				let input = this.messageTreeInput;
				let dataList = []; //最终显示的数据集合
				// console.log('搜索',input,'--\--',this.data);
				//遍历结果集, 检索目标数据
				this.dataListShow(dataList, this.data, input, '');

				// console.log('最后的数据集合>>',dataList);
				this.data = [...dataList];
				this.$loading().close();
			},
			// 新增笔记
			async insertMessage() {
				if (this.input == "") {
					this.input = "新增的笔记";
				}
				// console.log('新增数据>>', this.insertParentData);
				await messageTreeService.insertMessageTreeDataByTid({
					title: this.input,
					parentid: this.insertParentData.id,
					uid: this.uid
				}).then(resp => {
					// console.log("新增结果>>", resp);
					if (resp.code === 200) {
						this.$message({
							message: "新增成功!",
							type: 'success',
							duration: 2000,
							showClose: true
						});
						this.input = "";
						document.getElementById("cardDiv").style = "display: none;";
					}
				}).catch(err => {
					console.log("新增报错>>", err);
				});

				let user = jsUtils.getLoginUser();
				// await this.selectMessageTreeData();
				await messageTreeService.getMessageTreeDataByTid({
					id: this.tidAll,
					uid: user.uid
				}).then(resp => {
					// console.log("查询结果为>>", resp);
					if (resp.code === 200) {
						let id = this.insertParentData.id;
						let datas = this.listByItem(resp.data.result, this.insertParentData.id);
						// console.log("目标数据对象>>",datas)
						let dataItem = {};
						datas.messageTreeList.forEach(item => {
							dataItem = {
								...item
							};
						});
						this.insertParentData.messageTreeList.push(dataItem);
					} else if (resp.code == 201) {
						this.$message({
							message: "此笔记已经删除了, 请确认!",
							type: 'error',
							duration: 2000,
							showClose: true
						});
						this.data = [];
					}
				}).catch(err => {
					console.log("查询出错>>", err);
				});

				this.fullscreenLoading = false;
				//修改新增状态
				this.insertLogo = false;
				// this.$loading().close();
			},
			// 迭代遍历目标数组, 找到数组中目标对象id的对象数据
			listByItem(list, id) {
				for (let item of list) {
					if (item.id == id) {
						return item;
					} else if (item.messageTreeList && item.messageTreeList.length > 0) {
						let result = this.listByItem(item.messageTreeList, id);
						if (result) {
							return result;
						}
					}
				}
				return null; // 如果没有找到匹配的项，则返回 null
			},
			//取消新增按钮
			async downClick() {
				this.fullscreenLoading = false;
				this.input = "";
				document.getElementById("cardDiv").style = "display: none;";
				document.getElementById("cardDivUpdate").style = "display: none;";
				//显示工具条
				this.$refs.messageRef.classList.remove("messageBodyClass");
				//修改调整状态
				this.updateLogo = false;
				//修改新增状态
				this.insertLogo = false;
			},
			// 树形节点变化的方法--	新增
			async append(data) {
				// console.log("点击了新增>>", data);
				this.fullscreenLoading = true;
				//触发新增状态
				this.insertLogo = true;
				//隐藏滚动条
				// document.getElementById("messageBody").style = "overflow: hidden;";
				// console.log(this.$refs.messageRef);
				this.$refs.messageRef.className = "messageBodyClass";
				// this.$loading({
				// 	lock: true,
				// 	text: 'Loading',
				// 	spinner: 'el-icon-loading',
				// 	background: 'rgba(0, 0, 0, 0.7)'
				// });
				this.insertParentData = data;
				// 显示卡片弹出层
				document.getElementById("cardDiv").style = "display:block;";
			},
			// 树形节点变化的方法--	调整
			async remove(node, data) {
				// console.log("调整触发>>", data.id);
				//触发修改状态
				this.updateLogo = true;
				this.fullscreenLoading = true;
				// console.log(this.$refs.selectInput.$el.style);
				// console.log(data);
				//隐藏滚动条
				this.$refs.messageRef.classList.add("messageBodyClass");
				document.getElementById("cardDivUpdate").style = "display: block ;";
				this.formLabelAlign = Object.assign({}, data); //改变要改入的值
				for (var i = 1; i <= data.orderId; i++) {
					this.order_id_list.push(i);
				}
			},
			// 调整笔记标题的按钮事件
			async savaClickMessageTree() {
				await messageTreeService.udpateMessageTreeDataByTid(this.formLabelAlign).then(resp => {
					// console.log("resp", resp);
					if (resp.code === 200) {
						this.$message({
							message: "调整成功!",
							type: 'success',
							duration: 2000,
							showClose: true
						});
						this.selectMessageTreeData();
					}
				}).catch(err => {
					console.log("err>>", err);
				}).finally(() => {
					this.downClick();
				});
			},
			// 删除笔记标题以及笔记
			async deleteMessageTree() {
				document.getElementById("cardDivUpdate").style = "display: none ;";
				await messageService.deleteMessageByTid({
					id: this.formLabelAlign.id
				}).then(resp => {
					// console.log("执行完毕>>", resp);
					if (resp.code === 200) {
						this.$message({
							message: "删除成功!",
							type: 'success',
							duration: 2000,
							showClose: true
						});
						this.removeByIdForIndex(this.data, this.formLabelAlign.id);
					}
					// console.log("执行完毕>>", this.messageData);
				}).catch(err => {
					console.log("执行出错>>", err);
					this.updateLogo = false;
				});
				// await this.selectMessageTreeData();
				
				this.updateLogo = false;
				this.fullscreenLoading = false;
			},
			//根据索引删除数组中对象
			removeByIdForIndex(arr, id) {
				let index = -1 ;
				index = arr.findIndex(item => item.id === id);
				if(index == -1){
					for(let item of arr){
						this.removeByIdForIndex(item.messageTreeList,id);
					}
				}else{
					// console.log("删除");
					arr.splice(index, 1);
				}
				return arr;
			},
			//查询对象数组中, 指定对象属性id的对象数据
			removeById(arr, id) {
				return arr.filter(item => item.id !== id);
			},
			//查询树形菜单数据
			async selectMessageTreeData() {
				let that = this;
				let user = jsUtils.getLoginUser();
				// console.log("message>>", that.messageData);
				await messageTreeService.getMessageTreeDataByTid({
					id: this.tidAll,
					uid: user.uid
				}).then(resp => {
					// console.log("查询结果为>>", resp);
					if (resp.code === 200) {
						that.data = resp.data.result;
						that.dataBak = resp.data.result;
						//添加默认展开的节点key数据
						let list = resp.data.result;
						let keys = [];
						// console.log("list>>",list[0]);
						keys.push(list[0].id);
						let listItem = list[0];
						listItem.messageTreeList.forEach((item) => {
							// console.log("遍历中>>",item);
							keys.push(item.id);
						});
						that.dataShowListBak = [...keys];
						// console.log("最后集合>>", that.dataShowListBak);

					} else if (resp.code == 201) {
						this.$message({
							message: "此笔记已经删除了, 请确认!",
							type: 'error',
							duration: 2000,
							showClose: true
						});
						this.data = [];
					}
				}).catch(err => {
					console.log("查询出错>>", err);
				});
				//手动调用展开方法
				// this.dataShowList = [...this.dataShowListBak];
				let node = this.$refs.orgTree.getNode(this.data[0].id);
				// console.log("node>>",node);
				node.expand()
				// console.log("数据集合>>", this.data);
				// console.log("最后集合>>", this.dataShowList,node);
			},
			// 树形菜单点击事件
			async handleNodeClick(data) {
				// console.log("触发树菜单事件",data);
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				// this.fullscreenLoading = true;
				// console.log(data);
				this.tid = data.id;
				await this.selectByTid();
				this.$loading().close();
				// this.fullscreenLoading = false;
			},
			// 查询指定tid的笔记
			async selectByTid() {
				// console.log("触发事件");
				if (this.updateLogo || this.insertLogo) {
					// console.log('进入',this.updateLogo,this.insertLogo);
					return;
				}
				await messageService.getMessageByTid({
					tid: this.tid,
					uid: this.uid
				}).then(resp => {
					// console.log("执行完毕>>", resp);
					if (resp.code === 200) {
						this.messageData = resp.data.result[0];
						this.text = this.messageData.text;
						// console.log("笔记挂载完毕>>",this.messageData);
					}
					// console.log("执行完毕>>", this.messageData);
				}).catch(err => {
					console.log("执行出错>>", err);
				}).finally(() => {});
			},
			//点击保存后触发的事件
			async mdSava(text, html) {
				if (this.messageData != null) {
					// console.log("触发修改保存", this.messageData, text, html);
					let time = jsUtils.getDateTime(); //当前时间
					// console.log(time);
					this.messageData.updateTime = time;
					this.messageData.uid = this.uid;
					this.messageData.updateUser = this.uid;
					this.messageData.htmlText = html;
					this.messageData.text = text;
					//修改调整状态
					this.updateLogo = false;
					await messageService.updateMessageByTid(this.messageData).then(resp => {
						// console.log("修改完毕>>",resp);
						if (resp.code === 200) {
							this.$message({
								message: resp.message,
								type: "success",
								duration: 2000,
								showClose: true
							});
						}
					}).catch(err => {
						console.log("修改报错>>", err);
					});
				} else {
					console.log("触发新增保存", this.messageData, text, html);
				}
			},
			//单张图片上传
			async uploadImage(event, insertImage, files) {
				// console.log("触发图片上传处理事件>>", "event:", event, "insertImage>>", insertImage, "files>>", files);
				// 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
				let fileImage = files[0];
				// console.log("file>>", fileImage);
				// 新建form表单
				const fd = new FormData();
				let imagePath = "";
				fd.append("uploadFile", fileImage);
				// console.log("fd>>", fd);
				await messageService.imageUploadByOne(fd).then(resp => {
					// console.log("上传结果>>", resp);
					if (resp.data.result != "" && resp.data.result != null) {
						console.log("上传结果>>", resp.data.result);
						imagePath = resp.data.result;
					}
				}).catch(err => {
					console.log("上传出错>>", err);
				});
				// console.log("上传操作执行完毕!");

				// 此处只做示例
				insertImage({
					url: imagePath,
					desc: 'image',
					// width: 'auto',
					// height: 'auto',
				});
			},
		},
	}
</script>

<style>
	/* 文件上传div父级 */
	#uploadMessageUpdateDivParent {
		width: 200px;
		/* display: flex; */
		position: fixed;
		top: 5px;
		right: 160px;
		z-index: 999999;
	}

	/* 弹出卡片样式 */
	.textMessage {
		font-size: 14px;
	}

	.itemMessage {
		padding: 18px 0;
	}

	.box-cardMessage {
		width: 480px;
		margin: 10px;
	}

	/* 新增笔记卡片弹出层 */
	#cardDiv {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}

	/* 调整笔记卡片的弹出层 */
	#cardDivUpdate {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%);
		z-index: 9999;
		display: none;
	}

	/* 弹出卡片样式 */

	#messageBody {
		position: relative;
		width: 100%;
		/* float: left; */
	}

	/* 工具条隐藏的样式 */
	.messageBodyClass {
		overflow: hidden;
	}

	/* 左侧标题栏 */
	#leftDiv {
		width: 360px;
		height: 90%;
		float: left;
	}

	/* 左侧标题栏中下半部分标题展示div */
	#leftDivChertDiv {
		height: 90%;
		overflow: auto;
		/* 设置滚动条宽度 */
		scrollbar-width: thin;
		/* 设置滚动条颜色 */
		scrollbar-color: #999 transparent;
	}

	/* 右侧编辑栏 */
	#rightDiv {
		/* width: 1000px; */
		/* display: inline; */
		overflow: auto;
		height: 100%;
		/* 设置滚动条宽度 */
		scrollbar-width: thin;
		/* 设置滚动条颜色 */
		scrollbar-color: #999 transparent;
		float: left;
	}

	/* 树形菜单插槽 */
	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}
</style>