<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="UserComponentParentDiv"></div>
	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import LoginService from '../service/login.js'

	export default {
		name: 'UserComponent',
		data() {
			return {}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {
			// console.log("调用mounted");
			this.$loading({
				text: '加载中'
			});
			//初始化页面大小
			jsUtils.PassParentInitTopHeight("UserComponentParentDiv");
			
			this.$loading().close();
		},
		methods: {
			// 
		},
		beforeDestroy() {
			// console.log("销毁前调用");
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>

</style>
