<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="messageShowNewParent" class="scrollbar-overlay-messageTreeNew">
			<!-- <div id="message" v-html="messageData.htmlText"></div> -->
			<div id="messageMessageTreeNew">
				<v-md-editor ref="editorMessageTreeNew" v-model="messageData.text" :include-level="[1,2,3,4,5,6]"
					mode='preview' @copy-code-success="handleCopyCodeSuccess" :default-show-toc="1==1"
					left-toolbar="undo" right-toolbar="preview toc sync-scroll fullscreen">
				</v-md-editor>
				<!-- 查看数量, 点赞数量以及修改日期 -->
				<div class="messageTreeNewBottomDiv messageTreeNewShowBottomDiv">
					<div class="messageTreeNewBottomDivLeftDiv">
						<i class="el-icon-search-MessageTreeNewIcon" style="width: 16px; height: 16px;"></i><span
							class="messageTreeNewDataSpan1">{{messageData.browseNum}}</span>
						<i class="el-icon-thumb_MessageTreeNewIconRight" style="width: 16px; height: 16px;"></i><span
							class="messageTreeNewDataSpan2">{{messageData.likeNum}}</span>
					</div>
					<div class="messageTreeNewBottomDivRightDiv">
						<!-- <span class="messageTreeNewDataSpan3">{{messageData.username}}</span> -->
						<span class="messageTreeNewDataSpan4">{{formatDate(messageData.updateTime)}}</span>
					</div>
				</div>
			</div>

			<!-- 右侧整章笔记目录区域 -->
			<div id="fixedDivMessageTreeNew">
				<div style="margin-top: 10px;margin-bottom: 10px;font-weight: bolder;">---整章目录---</div>
				<div class="smooth-transition" id="messageShowNewListDiv" v-if="isVisible">
					<el-tree :data="MessageTreeList" :default-expand-all="true" :expand-on-click-node="false"
						:props="messageTreeListProps" @node-click="MessageTreeListNodeClick"></el-tree>
				</div>
				<div style="position: relative;display: flex; justify-content:center;">
					<div id="messageShowNewImageDiv" class="background-image-container2" style="width: 30px; height: 30px;" @click="toggleDiv">
					</div>
				</div>
			</div>


			<div id="MessageShouNewRightDiv" v-if='false'>
				<div style="padding-top: 20px;margin-top: 60px;">--整章目录--</div>
				<div id="messageShowNewRightDivByDiv">
					<el-tree :data="treeData" node-key="id" :default-checked-keys="[1]" :expand-on-click-node="false"
						:props="defaultProps" @node-click="handleNodeClickRight" :highlight-current="true"
						:default-expand-all="true">
						<!-- 树节点的操作按钮 -->
						<!-- <span class="custom-tree-node" slot-scope="{ node, data }">
							<span>{{ node.label }}</span>
							<span>
								<el-button type="text" size="mini" @click="() => append(data)">
									添加
								</el-button>
								<el-button type="text" size="mini" @click="() => remove(node, data)">
									调整
								</el-button>
							</span>
						</span> -->
					</el-tree>
				</div>
			</div>

			<!-- 评论区域 -->
			<div class="comments-container-messageTreeNew">
				<div class="comment-messageTreeNew">
					<div class="comment-author-messageTreeNew">笔记留言</div>
					<div class="comment-text-messageTreeNew">请留下你的看法吧...</div>
					<div class="comment-time-messageTreeNew">2024-01-01</div>
				</div>
				<div class="comment-messageTreeNew" v-for="(messageComment,index) in messageCommentList"
					:key="messageComment.id">
					<div class="comment-author-messageTreeNew">{{messageComment.username}}</div>
					<div class="comment-text-messageTreeNew">{{messageComment.text}}</div>
					<div class="comment-time-messageTreeNew">{{formatDate(messageComment.updateTime)}}</div>
				</div>

				<!-- Add more comments here -->

				<div class="comment-form-messageTreeNew">
					<textarea placeholder="想要说些什么" v-model="messageCommentInput"></textarea>
					<button @click="insertMessageComment">发表一下看法</button>
				</div>
			</div>

			<!-- 笔记内容的菜单标题 -->
			<div id="messageShowNewMenu">
				<div style="margin-top: 10px;margin-bottom: 10px;font-weight: bolder;">---目录---</div>
				<el-tree :data="billData" :default-expand-all="true" :expand-on-click-node="false"
					:props="billDefaultProps" @node-click="handleNodeClick"></el-tree>
				<!-- <div style="height: 60px;"></div> -->
			</div>
			<div id="rainBox"></div>
		</div>
	</div>
</template>

<script>
	//笔记查看--新
	import messageService from '../service/Message.js'
	import jsUtils from '../utils/jsUtils.js'
	import messageTreeService from '../service/MessageTree.js'
	import messageCommentService from '../service/MessageCommentNew.js'

	export default {
		name: 'MessageShowNew',
		data() {
			return {
				id: 0, //	要展示的笔记id
				messageData: {}, //笔记内容
				messageCommentList: [], //笔记评论数组
				messageCommentInput: '', //评论新增输入框
				treeData: [], //树菜单绑定属性
				data: [],
				billData: [], //笔记目录
				defaultProps: {
					children: 'messageTreeList',
					label: 'title'
				},
				billDefaultProps: {
					children: 'subSections',
					label: 'title'
				},
				messageTreeListProps: {
					children: 'messageTreeList',
					label: 'title'
				},
				MessageTreeList: [], //整章目录数据
				showKeys: [1], //默认展开的树菜单id
				isVisible: true, //整章笔记目录区域div显示隐藏控制
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		watch: {
			$route(to, from) {
				// 监听路由参数变化
				if (to.query.id !== from.query.id) {
					//this.selectByTid();
				}
			}
		},
		async mounted() {
			// console.log("执行到");
			//开启大遮罩
			jsUtils.loadingAllIsNone(false, 'loadingAll');
			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			// console.log("调用mounted");
			this.id = this.$route.query.id; //从地址中获取tid
			//初始化页面大小
			jsUtils.PassParentInitTopHeight("messageShowNewParent");
			// jsUtils.PassParentInitTopHeightOrsearchBody("fixedDivMessageTreeNew");
			document.getElementById("messageShowNewListDiv").style.height = 0;

			//jsUtils.PassParentInitTopHeight("MessageShouNewRightDiv");		//右侧标题栏
			//jsUtils.PassParentInitTopHeight("messageShowNewRightDivByDiv");		//右侧标题栏子级
			await this.selectByTid();
			jsUtils.yu("rainBox"); //下雨效果
			let leftPX = jsUtils.elementLeftPX("messageMessageTreeNew");
			// console.log("leftPX",leftPX.leftPx);
			document.getElementById("messageShowNewMenu").style.width = (leftPX.leftPx - 10) + 'px';

			// 查询属性菜单
			// console.log("查询菜单");
			//树形菜单查询
			// await this.selectMessageTreeData();

			// this.$refs.editor.$el.style = "background-color: rgba(100, 100, 100, 0.6);";
			// console.log(document.getElementsByClassName("vuepress-markdown-body")[0]);
			//改变Markdown编辑器内部的背景色
			document.getElementsByClassName("github-markdown-body")[0].style =
				"background-color: rgba(200, 200, 200, 0.6);";

			jsUtils.loadingAllIsNone(true, "loadingAll"); //隐藏大遮罩

			this.$loading().close();
		},
		methods: {
			//div触发动画事件
			toggleDiv() {
				// this.isVisible = !this.isVisible;
				let el = document.getElementById("messageShowNewListDiv");
				let pr = document.getElementById("fixedDivMessageTreeNew");
				let im = document.getElementById("messageShowNewImageDiv");
				// 切换按钮类, 切换div按钮背景图样式
				if (im.classList.contains('background-image-container')) {
				  // 类名存在时的操作
				  im.classList.remove("background-image-container");
				  im.classList.add("background-image-container2");
				  el.style.height=0;
				  pr.style.height=60+"px";
				} else {
				  // 类名不存在时的操作
				  im.classList.remove("background-image-container2");
				  im.classList.add("background-image-container");
				  let heightBody = document.documentElement.clientHeight;
				  // console.log("可视区窗口高度:" + heightBody) // 可视区窗口高度
				  let heightNum = document.getElementById("messageShowNewListDiv").offsetTop;
				  // console.log("当前元素与页面顶部距离", heightNum) // 当前元素与页面顶部距离
				  document.getElementById("messageShowNewListDiv").style.height = heightBody - heightNum - 60 - 50 + "px";
				  //父级div高度
				  jsUtils.PassParentInitTopHeightOrsearchBody("fixedDivMessageTreeNew");
				}
			},
			// 树形菜单点击事件
			async handleNodeClickRight(data) {
				// console.log("点击数据>>",data);
				let routeUrl = this.$router.resolve({
					path: "/messageShowNew",
					query: {
						id: data.id
					}
				});
				window.location.href = routeUrl.href;
			},
			//新增评论
			async insertMessageComment() {
				// console.log("新增的评论>>", this.messageCommentInput);
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				await messageCommentService.insertMessageCommentNew({
					"mId": this.id,
					"text": this.messageCommentInput
				}).then(resp => {
					// console.log('新增完毕>>', resp);
					this.selectByTid();
				}).catch(err => {
					console.log("报错>>", err);
				});

				this.$loading().close();
			},
			//粘贴代码块
			handleCopyCodeSuccess(code) {
				console.log("触发复制>>", code);
			},
			//查询树形菜单数据
			async selectMessageTreeData() {
				let that = this;
				let user = jsUtils.getLoginUser();
				// console.log("message>>", that.messageData);
				await messageTreeService.getMessageTreeDataByTid({
					id: that.messageTid,
					uid: user.uid
				}).then(resp => {
					// console.log("结果为>>", resp);
					if (resp.code === 200) {
						that.data = resp.data.result;
						this.showKeys = [resp.data.result[0].id]; //添加默认展开项
					}
				}).catch(err => {
					console.log("查询出错>>", err);
				});
			},
			// 树形菜单点击事件
			async handleNodeClick(data) {
				// console.log(data);
				// 获取具有自定义滚动条的元素
				const containerElement = document.getElementById('messageShowNewParent');
				// 滚动到目标位置
				containerElement.scrollTo({
					top: data.height,
					behavior: 'smooth' // 平滑滚动效果
				});
			},
			async MessageTreeListNodeClick(data) {
				console.log(data);
				this.id = data.id;
				await this.selectByTid();
				const newState = {
					data: 'some state data'
				};
				const newUrl = '/messageShowNew?id=' + this.id;
				history.replaceState(newState, "", newUrl);
			},
			// 查询指定笔记
			async selectByTid() {
				this.$loading({
					lock: true,
					text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});

				await messageService.selectMessageById({
					id: this.id
				}).then(resp => {
					// console.log("查询结果>>", resp.data.messageTreeList);
					if (resp.code === 200) {
						this.messageData = resp.data.data;
						this.messageCommentList = resp.data.messageComments;
						document.title = resp.data.data.title;
						this.treeData = resp.data.messageTreeData;
						this.MessageTreeList = resp.data.messageTreeList;
					}
				}).catch(err => {
					console.log("查询报错>>", err);
				});

				// 在下次 DOM 更新循环结束后执行回调函数
				await this.$nextTick(() => {
					// 这里可以进行一些处理，确保 DOM 已经渲染完毕
					// console.log('DOM 已经更新完毕');
					// 获取所有页面中的 h1 到 h6 标签
					const headings = document.querySelectorAll('h1, h2, h3, h4');
					// console.log('headings>>', headings);
					// 创建菜单容器
					const menuContainer = document.getElementById('messageShowNewMenu');
					// 获取要删除的子元素
					var childElement = menuContainer.getElementsByTagName("ul");
					// console.log(childElement);
					if (childElement.length > 0) {
						// 确保子元素存在于父元素下
						if (menuContainer.contains(childElement[0])) {
							// 从父元素中移除子元素
							menuContainer.removeChild(childElement[0]);
						} else {
							console.log("子元素不存在于父元素中");
						}
					}

					// 笔记目录数据
					let listTree = [];
					const h1Elements = document.querySelectorAll('h1');
					// 遍历每个h1元素
					h1Elements.forEach(h1 => {
						// 创建一个对象来表示当前级别的标题及其子标题
						let section = {
							title: h1.textContent.trim(),
							height: h1.offsetTop,
							subSections: []
						};

						// 查找当前h1元素后面的同级或下一级的h2元素
						let nextElement = h1.nextElementSibling;
						while (nextElement && nextElement.tagName !== 'H1') {
							if (nextElement.tagName === 'H2') {
								// 如果是h2元素，则添加到当前section的subSections中
								let subsection = {
									title: nextElement.textContent.trim(),
									height: nextElement.offsetTop,
									subSections: []
								};

								// 查找h2元素后面的同级或下一级的h3元素
								let nextSubElement = nextElement.nextElementSibling;
								while (nextSubElement && nextSubElement.tagName !== 'H1' &&
									nextSubElement.tagName !== 'H2') {
									if (nextSubElement.tagName === 'H3') {
										let subsection3 = {
											title: nextSubElement.textContent.trim(),
											height: nextSubElement.offsetTop,
											subSections: []
										};
										// 如果是h3元素，则添加到当前subsection的subSubSections中
										subsection.subSections.push(subsection3);
									}
									nextSubElement = nextSubElement.nextElementSibling;
								}

								section.subSections.push(subsection);
							}
							nextElement = nextElement.nextElementSibling;
						}

						// 将当前section添加到结果数组中
						listTree.push(section);
					});
					this.billData = [...listTree];
					// console.log("billData>>",this.billData);
					/*
					// 创建菜单列表
					const menuList = document.createElement('ul');
					// 遍历所有标题标签，创建菜单项并添加到列表中
					headings.forEach((heading, index) => {
						const listItem = document.createElement('li');
						const link = document.createElement('a');
						if (heading.tagName == "H1")
							listItem.classList.add("messageshowNewLiH1");
						if (heading.tagName == "H2")
							listItem.classList.add("messageshowNewLiH2");
						if (heading.tagName == "H3")
							listItem.classList.add("messageshowNewLiH3");
						if (heading.tagName == "H4")
							listItem.classList.add("messageshowNewLiH4");
						// 设置链接的文本
						link.textContent = heading.textContent;

						// 为链接添加点击事件，触发滚动到相应位置
						listItem.addEventListener('click', function() {
							// 获取目标标题的位置
							const targetOffset = heading.offsetTop;

							// 获取具有自定义滚动条的元素
							const containerElement = document.getElementById(
								'messageShowNewParent');
							// 滚动到目标位置
							containerElement.scrollTo({
								top: targetOffset,
								behavior: 'smooth' // 平滑滚动效果
							});
						});
						// 将链接添加到列表项
						listItem.appendChild(link);
						// 将列表项添加到菜单列表
						menuList.appendChild(listItem);
					});
					// console.log("listTree>>",listTree);
					// 将菜单列表添加到菜单容器
					menuContainer.appendChild(menuList);
					*/
				});

				this.$loading().close();
			},
			//日期时间类型格式化
			formatDate(date) {
				const myDate = new Date(date);
				const year = myDate.getFullYear();
				const month = String(myDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
				const day = String(myDate.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			}
		},
		beforeDestroy() {
			// console.log("销毁前调用");
		},
		destroyed() {
			// console.log("销毁后调用");
		}
	}
</script>

<style>
	/* div动画类 */
	.smooth-transition {
		transition: height 0.3s ease-in-out;
	}

	.smooth-transition.hidden {
		height: 0;
		/* 当添加 hidden 类名时，高度变为 0 */
	}

	/* 右侧整章目录区域 */
	#fixedDivMessageTreeNew {
		width: 300px;
		height: 60px;
		position: fixed;
		/* 将 div 固定在页面 */
		right: 40px;
		/* 距离页面右边 20px */
		top: 60px;
		/* 垂直居中，可根据需要调整 */
		/* transform: translateY(-50%); */
		/* 垂直居中的兼容写法 */
		background-color: #f0f0f0;
		/* 设置背景颜色 */
		padding: 10px;
		/* 可根据需要调整内边距 */
		border: 1px solid #ccc;
		/* 设置边框 */
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
		/* 设置阴影效果 */
		z-index: 1000;
		/* 可选项，设置 z-index 属性 */
		transition: height 0.3s ease-in-out;
	}

	#messageShowNewListDiv {
		height: 100%;
		width: 100%;
		overflow-y: auto;
	}

	/* 整章目录区域下部按钮 */
	.background-image-container {
		/* 设置容器的大小和其他样式 */
		width: 100%;
		height: 400px;
		/* 示例中的高度 */
		background-image: url('/src/assets/ss2.png');
		/* 替换成你的图片路径 */
		background-size: cover;
		/* 可选项，控制背景图片大小 */
		background-position: center;
		/* 可选项，控制背景图片位置 */
		cursor: pointer;
	}
	.background-image-container2 {
		/* 设置容器的大小和其他样式 */
		width: 100%;
		height: 400px;
		/* 示例中的高度 */
		background-image: url('/src/assets/ss1.png');
		/* 替换成你的图片路径 */
		background-size: cover;
		/* 可选项，控制背景图片大小 */
		background-position: center;
		/* 可选项，控制背景图片位置 */
		cursor: pointer;
	}


	/* 右侧属性菜单 */
	#MessageShouNewRightDiv {
		position: fixed;
		width: 400px;
		margin-right: 30px;
		top: 0px;
		right: 0px;
	}

	#messageShowNewRightDivByDiv {
		overflow: auto;
	}

	/* 清除默认的列表样式 */
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	/* li {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	li a{
		width: 100%;
	} */

	/* 笔记内容的标题菜单 */
	#messageShowNewMenu {
		/* border: 1px solid red; */
		height: 100%;
		overflow: auto;
		position: fixed;
		top: 60px;
		/* background-color: #ddd; */
		padding-bottom: 20px;
		margin-left: 5px;
		margin-top: 10px;
		border-radius: 5px;
	}

	/* 笔记内容标题列表的样式 */
	#messageShowNewMenu ul {
		cursor: pointer;
		text-align: left;
	}

	#messageShowNewMenu ul li a {
		text-decoration: underline;
		/* color: blue; */
	}

	/* 笔记内容的标题格式H2 */
	.messageshowNewLiH2 {
		padding-left: 40px;
	}

	/* 笔记内容的标题格式H1 */
	.messageshowNewLiH1 {
		padding-left: 20px;
	}

	/* 笔记内容的标题格式H3 */
	.messageshowNewLiH3 {
		padding-left: 60px;
	}

	/* 笔记内容的标题格式H3 */
	.messageshowNewLiH4 {
		padding-left: 80px;
	}

	.scrollbar-overlay-messageTreeNew {
		position: fixed;
		top: 0;
		right: 0;
		width: 12px;
		/* 设置滚动条的宽度 */
		height: 100%;
		background-color: #fff;
		/* 设置滚动条的背景色 */
		z-index: 999;
		/* 设置 z-index 以确保滚动条在其他元素之上 */
		overflow-y: scroll;
		/* 启用纵向滚动条 */
	}

	.vuepress-markdown-body {
		/* background-color: black; */
	}

	#messageShowNewParent {
		position: relative;
		width: 100%;
		/* background-color: rgba(100, 100, 100, 0.4); */
		/* background: url('~@/assets/a2.jpeg') no-repeat center; */
		/* background-size: 100% 100%; */
		/* opacity: 0.7; */
		/* height: 100%; */
	}

	.messageTreeNewShowBottomDiv {
		border-radius: 8px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		background-color: #f8f8f8;
	}

	/* 笔记内容区域 */
	#messageMessageTreeNew {
		/* position: absolute;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%); */
		margin: 0 auto;
		/* background-color: rgba(100, 100, 100, 0.6); */
		width: 1050px;
		/* height: 100%; */
	}

	/* 评论区的样式 */
	body {
		font-family: Arial, sans-serif;
		margin: 0;
		padding: 0;
		background-color: #f8f8f8;
	}

	[class$="-messageTreeNew"] {
		/* 以 -messageTreeNew 结尾的类名样式 */
		font-family: Arial, sans-serif;
		margin: 0;
		padding: 0;
		background-color: #f8f8f8;
	}

	.comments-container-messageTreeNew {
		width: 1050px;
		margin: 0px auto;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		text-align: left;
	}

	.comment-messageTreeNew {
		border-bottom: 1px solid #eee;
		padding: 10px;
	}

	.comment-messageTreeNew:last-child {
		border-bottom: none;
	}

	.comment-author-messageTreeNew {
		font-weight: bold;
		color: #333;
		cursor: pointer;
	}

	.comment-text-messageTreeNew {
		margin-top: 5px;
		color: #666;
	}

	.comment-time-messageTreeNew {
		font-size: 12px;
		color: #999;
	}

	.comment-form-messageTreeNew {
		padding: 20px;
		background-color: #f8f8f8;
		border-top: 1px solid #eee;
	}

	.comment-form-messageTreeNew textarea {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
		margin-bottom: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		resize: vertical;
	}

	.comment-form-messageTreeNew button {
		padding: 8px 16px;
		background-color: #4caf50;
		color: #fff;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-size: 14px;
	}

	/* 评论区的样式-end */
</style>