
import http from '../utils/http.js'

const loginService = {
	/**登录方法
	 * @param {Object} bm	用户名
	 * @param {Object} pass	密码
	 */
	async login(bm,pass){
		return new Promise((resolve,reject)=>{
			http.post("/api/Users/getUsersByOne",{
				bm:bm,
				password:pass
			}).then(resp=>{
				resolve(resp.data);
			}).catch(err=>{
				reject(err);
			});
		});
	},
	
	/**
	 * 判断是否登录的方法
	 */
	async LoginOrNot(){
		return new Promise((resolve,reject)=>{
			http.post("/api/Users/LoginOrNot",{}).then(resp=>{
				resolve(resp.data);
			}).catch(err=>{
				reject(err);
			});
		});
	},
}

export default loginService ;





