//这里我们对axios进行简单的封装。
import axios from 'axios' //引入 axios
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import store from '@/store/index';
import router from '@/router/index';

// import {
// 	localStorage,
// 	sessionStorage
// } from 'mp-storage';
import {
	Message
} from 'element-ui';

//请求拦截器
axios.interceptors.request.use(config => {
	// console.log(config);
	// console.log("进入axios");
	// window.alert("输出:");
	//如果存在token，则请求携带这token
	// console.log(store.state.token);
	//改变图片上传的请求头
	// if(config.url == "/api/picture/doFileUpload"){
	// 	// config.headers["Content-Type"] = "multipart/form-data; boundary=----WebKitFormBoundaryAFqTJ80uGQn1LRGO";
	// 	// config.headers["Accept"] = "application/json, text/plain, */*";
	// }
	if (window.localStorage.getItem("jwt")) {
		// console.log("触发请求头改变");
		config.headers["jwt"] = window.localStorage.getItem("jwt");
	}
	return config;
}, error => {
	console.log(error);
});

axios.interceptors.response.use(success => {
	// console.log("请求返回值>>", success);
	let resp = success.data;
	if (resp.code === 201 && resp.message == "重新登录") {
		Message.error({
			message: '尚未登陆，请登录!',
			showClose: true,
		});
		window.localStorage.clear();
		router.push("/login");
		return success;
	}
	//业务逻辑错误
	if (success.status && success.status == 200) {
		if (success.data.code == 500 ||
			success.data.code == 401 ||
			success.data.code == 403 || success.data.code == 20001) {
			if (success.data.code == 401) { //报错信息为未登录401时,手动跳转登录页面
				// console.log("执行到此");
				// window.sessionStorage.clear();
				router.replace('/');
			}
			Message.error({
				message: success.data.message,
				showClose: true,
			});
			if (success.data.code == 20001) {
				return success.data;
			}
			return;
		}
		// console.log(success);
		/* if (success.data.message) {
			Message.success({
				message: success.data.message,
				showClose: true,
			});
		} */
	}
	return success;
}, error => {
	console.log("请求返回值>>", error);
	if (error.response.code == 504 || error.response.code == 404) {
		Message.error({
			message: '服务器被吃了(⊙﹏⊙)',
			showClose: true,
		});
	} else if (error.response.code == 403) {
		Message.error({
			message: '权限不足，请联系管理员！',
			showClose: true,
		});
	} else if (error.response.data.code == 401) {
		Message.error({
			message: '尚未登陆，请登录!',
			showClose: true,
		});
	} else if (error.response.data.code == 406) { //token过期或者非法
		Message.error({
			message: error.response.data.message,
			showClose: true,
		});
		router.push("/login");
	} else {
		if (error.response.data.message) {
			Message.error({
				message: error.response.data.message,
				showClose: true,
			});
		} else {
			Message.error({
				message: '未知错误！',
				showClose: true,
			});
		}
	}
	return error.response;
});

// 创建 axios 实例
const http = axios.create({
	timeout: 60000, // 请求超时时间
})

http.get = (url, params) => {
	const config = {
		method: 'get',
		url: url
	}
	if (params) config.params = params
	return axios(config)
}

http.lget = (url, params, loading) => {
	let options = {
			lock: true,
			text: loading,
			spinner: "el-icon-loading",
			background: "rgba(255,255,255,0)",
			customClass: "loadingMask",
		},
		loader = ElementUI.Loading.service(options)
	const config = {
		method: 'get',
		url: url,
		timeout: 600000
	}
	if (params) config.params = params
	return http(config).then(resp => {
		loader.close();
		return resp;
	})
}

http.post = (url, params) => {
	const config = {
		method: 'post',
		url: url
	}
	if (params) config.data = params
	return axios(config)
}



http.put = (url, params) => {
	const config = {
		method: 'put',
		url: url
	}
	if (params) config.data = params
	return axios(config).then(resp => {
		return resp;
	})
}

http.delete = (url, params) => {
	const config = {
		method: 'delete',
		url: url
	}
	if (params) config.params = params
	return axios(config)
}

//导出
export default http
