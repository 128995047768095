<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="PassParent">
			<el-table id="tableBody" ref="tableRef" :data="tableData" style="width: 1250px ;margin: 0px 10px;"
				v-if="tableShow" @row-contextmenu="mouseRightClick" stripe fit :max-height="tableHeight"
				@cell-dblclick='cellClick'>
				<el-table-column prop="id" label="序号" width="50">
				</el-table-column>
				<el-table-column prop="title" label="标题" width="220">
				</el-table-column>
				<el-table-column prop="account" label="账号" width="220">
				</el-table-column>
				<el-table-column :prop="tablePassTag" label="密码" width="120">
				</el-table-column>
				<el-table-column prop="mark" label="备注" width="320">
				</el-table-column>
				<el-table-column prop="updateTime" label="最后修改时间" width="180">
				</el-table-column>
				<!-- <el-table-column prop="zip" label="邮编">
				</el-table-column> -->
				<el-table-column label="查看密码" width="120">
					<template slot-scope="scope">
						<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
							查看密码
						</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- 右键菜单, element卡片 -->
			<el-card class="box-card" ref="mouse">
				<div class="text item" @click="mouseClick('insert')">
					新增密码信息
				</div>
				<div class="text item" @click="mouseClick('delete')">
					删除密码信息
				</div>
				<div class="text item" @click="mouseClick('quit')">
					关闭菜单
				</div>
			</el-card>

			<!-- 新增密码业务模块 -->
			<el-card class="box-cardPass" ref="insertPassRef">
				<div slot="header" class="clearfixPass">
					<span>卡片名称</span>
					<el-button style="float: right; padding: 3px 0" type="text" @click="passQuit">关闭</el-button>
				</div>
				<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" size="mini">
					<el-form-item label="标题">
						<el-input v-model="formLabelAlign.title"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="formLabelAlign.account"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="formLabelAlign.pass"></el-input>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="formLabelAlign.mark"></el-input>
					</el-form-item>
				</el-form>
				<div class="textPass itemPass">
					<!-- <div style="display: inline;position:relative;right: 20px;top: 20px;cursor: pointer;color: cyan;">123</div>
					<div style="display: inline;position:relative;left:20px;top: 20px;cursor: pointer;">456</div> -->
					<el-button style="float: right;margin: 0px 20px;" type="text" @click="insertButton('insert')">保存
					</el-button>
					<el-button style="float: right;margin: 0px 20px;" type="text" @click="insertButton('reset')">重置
					</el-button>
				</div>
			</el-card>

			<!-- 右键菜单,,停用 -->
			<div id="menu" class="menu">
				<div class="menu__item" onclick="log('1')">功能1</div>
				<div class="menu__item" onclick="log('2')">功能2</div>
				<div class="menu__item" onclick="log('3')">功能3</div>
				<div class="menu__item">功能4 <span class="icon"> > </span>
					<div id="submenu" class="submenu">
						<div class="submenu__item" onclick="log('4-1')">功能4-1</div>
						<div class="submenu__item" onclick="log('4-2')">功能4-2</div>
						<div class="submenu__item" onclick="log('4-3')">功能4-3</div>
						<div class="submenu__item" onclick="log('4-4')">功能4-4</div>
					</div>
				</div>
				<div class="menu__item" onclick="log(5)">功能5</div>
			</div>


		</div>
	</div>
</template>

<script>
	import http from '../utils/http.js'

	export default {
		name: 'PassComponent',
		components: {},
		data() {
			return {
				tableShow: false, //	表格展示标识
				tableHeight: '0px', //表格最大高度
				tablePassTag: 'passString', //	密码展示为*或者明码
				tableData: [],
				loadingBody: null, //大遮罩
				labelPosition: 'right', //表单对齐方式
				formLabelAlign: { //新增密码业务用的表单属性
					title: '',
					account: '',
					pass: '',
					mark: '',
					flag: '0',
					version: '0',
					createTime: '',
					updateTime: '',
					uid: ''
				},
				formLabelDataBack: { //新增密码业务用的表单属性备份
					title: '',
					account: '',
					pass: '',
					mark: '',
					flag: '0',
					version: '0',
					createTime: '',
					updateTime: '',
					uid: ''
				},
				deletePassId: -1, //要删除密码的id
			}
		},
		created() {
			let that = this;
			// console.log("PassComponent>>触发created");
			// 取消浏览器自带的右键菜单
			window.oncontextmenu = function(e) {
				//取消默认的浏览器自带右键
				e.preventDefault();
			}

		},
		async mounted() {
			// console.log("PassComponent>>调用mounted");
			let that = this;

			//先判断登录状态有没有问题
			let user = window.localStorage.getItem("user");
			let jwt = window.localStorage.getItem("jwt");
			// console.log("登录用户信息>>", user, "登录令牌>>", jwt);
			// console.log();
			// 未登录的统一登录
			if (jwt === null || user === null) {
				window.localStorage.clear();
				this.$message({
					message: "登录状态异常, 请重新登录!",
					type: 'warning',
					duration: 2000,
					showClose: true
				});
				this.$router.push("/");
				return;
			}

			this.loadingBody = this.$loading({
				lock: true,
				//text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			//初始化table位置
			await this.PassParentInit();
			//调整table位置
			/*this.$refs.tableRef.$el.style = "position;absolute; left: 50%;top: 50%; transform: translate(-50%,-50%);";
			this.$refs.tableRef.$el.getElementsByTagName("div")[14].style = "position:relative;";
			this.$refs.tableRef.$el.getElementsByTagName("div")[14].getElementsByTagName("table").style =
				"position;absolute; left: 50%;top: 50%; transform: translate(-50%,-50%);";
			console.log("123", this.$refs.tableRef.$el.getElementsByTagName("div")[14].getElementsByTagName("table"));*/
			//初始化查询密码数据
			let lod = 1;
			// console.log("执行到1");
			await this.selectPass().then(resp => {
				//console.log(resp);
				if (resp.code === 200) {
					let passList = resp.data.result;
					passList.forEach((item) => {
						let passString = {
							passString: '*********'
						}
						item = Object.assign(item, passString);
						//console.log(item);
					});
					this.tableData = passList;
				} else if (resp.code === 201 && resp.message == "重新登录") {
					lod = 2;
					if (that.loadingBody != null) {
						that.loadingBody.close();
						that.loadingBody = null;
					}
				}
			}).catch(err => {
				console.log(err);
				lod = 2;
			});
			if (lod === 2) {
				// console.log("执行到2");
				return;
			}
			//初始化右键菜单
			let card = this.$refs.mouse;
			card.$el.style = "display:none;";
			card.$el.getElementsByTagName("div")[0].style = "margin: 0;padding: 2px;";
			this.$refs.insertPassRef.$el.style = "display:none;";
			//console.log("卡片ref:",card.$el.getElementsByTagName("div"));

			// console.log("测试>>",this.$refs.tableRef);
			//设置浏览器鼠标右击事件
			document.getElementById("tableBody").onmousedown = function(e) {
				//取消默认的浏览器自带右键
				e.preventDefault();
				if (e.button == 2) {
					if (that.loadingBody === null) {
						//获取鼠标位置
						let xy = that.xOrY();
						//console.log("右击鼠标", xy);
						let cardStyle = "position: fixed;left: " + xy.x + "px;top: " + xy.y + "px;";
						//console.log("样式:", cardStyle);
						that.$refs.mouse.$el.style = cardStyle;
					}
				}
			}


			//关闭遮罩
			this.passQuit();
		},
		methods: {
			//通用http请求
			async httpOpen(url, data) {
				let that = this;
				return new Promise((resolve, reject) => {
					http.post("/api/" + url,
						data
					).then(resp => {
						resolve(resp.data);
					}).catch(err => {
						reject(err);
					});
				});
			},
			//开启遮罩方法
			async openLoading() {
				this.loadingBody = this.$loading({
					lock: true,
					//text: '加载中',
					//spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			},
			//查询密码数据方法
			async selectPassAll() {
				//初始化查询密码数据
				this.selectPass().then(resp => {
					//console.log(resp);
					if (resp.code === 200) {
						let passList = resp.data.result;
						passList.forEach((item) => {
							let passString = {
								passString: '*********'
							}
							item = Object.assign(item, passString);
							//console.log(item);
						});
						this.tableData = passList;
					}
				}).catch(err => {
					console.log(err);
				});
			},
			// 表格中鼠标右键触发
			mouseRightClick(row, column, event) {
				//console.log("右键表格", row.id, column, event);
				this.deletePassId = row.id;
			},
			//新增密码业务方法
			async insertPass() {
				let that = this;
				return new Promise((resolve, reject) => {
					http.post("/api/pass/insertPass",
						that.formLabelAlign
					).then(resp => {
						resolve(resp.data);
					}).catch(err => {
						reject(err);
					});
				});
			},
			//新增密码业务卡片中按钮事件
			async insertButton(str) {
				// console.log('点击',str);
				if (str === 'insert') {
					//开启遮罩
					this.loadingBody = this.$loading({
						lock: true,
						//text: '加载中',
						//spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					//console.log('loadingBody>>', this.loadingBody);
					let that = this;
					let myDate = new Date();
					let time = myDate.toLocaleString();
					this.formLabelAlign.createTime = time;
					this.formLabelAlign.updateTime = time;
					this.formLabelAlign.uid = JSON.parse(window.localStorage.getItem("user")).uid;
					//console.log('表单数据>>', this.formLabelAlign);
					await this.insertPass().then(resp => {
						//console.log("新增结果>>", resp);
						if (resp.code === 200) {
							this.formLabelAlign = Object.assign({}, this.formLabelDataBack); //	重置表单
							that.passQuit();
							//初始化查询密码数据
							this.selectPass().then(resp => {
								//console.log(resp);
								if (resp.code === 200) {
									let passList = resp.data.result;
									passList.forEach((item) => {
										let passString = {
											passString: '*********'
										}
										item = Object.assign(item, passString);
										//console.log(item);
									});
									this.tableData = passList;
								}
							}).catch(err => {
								console.log(err);
							});
						}
					}).catch(err => {
						console.log("新增报错>>", err);
					}).finally(() => {
						//console.log("finally>>",that.loadingBody);
						if (that.loadingBody != null) {
							that.loadingBody.close();
							that.loadingBody = null;
						}
					});
					//console.log('新增执行完毕');
				}

				if (str === 'reset') {
					// console.log("reset");
					this.formLabelAlign = Object.assign({}, this.formLabelDataBack);
					console.log("重置表单>>", this.formLabelAlign);
				}
			},
			//新增密码业务退出按钮
			async passQuit() {
				if (this.loadingBody != null) {
					await this.loadingBody.close();
					this.loadingBody = null;
					this.$refs.insertPassRef.$el.style = "display:none;";
					//console.log("样式>>",this.$refs.insertPassRef.$el.style);
				}
			},
			//右键菜单单击事件
			async mouseClick(str) {
				let that = this;
				//console.log("单击菜单>>",str);
				this.$refs.mouse.$el.style = "display: none;"; //关闭右键菜单
				if (str == "insert") {
					//console.log("进入插入");
					this.loadingBody = this.$loading({
						lock: true,
						//text: '加载中',
						//spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.5)'
					});
					//console.log("insert>>", this.$refs.insertPassRef);
					this.$refs.insertPassRef.$el.style =
						"position:absolute;z-index:9999; left: 50%;top: 50%; transform: translate(-50%,-50%);";
					// setTimeout(() => {
					// 	this.loadingBody.close();
					// 	this.loadingBody = null;
					// }, 2000);
				}
				if (str == "quit") {
					//console.log("退出菜单");
					this.passQuit();
					this.deletePassId = -1; //删除上传标识
				}
				if (str == 'delete') {
					that.openLoading();
					//console.log("删除>>", this.deletePassId);
					if (this.deletePassId === -1) {
						this.passQuit();
						return;
					}
					await this.httpOpen('pass/deletePass', {
						id: this.deletePassId,
						uid: JSON.parse(window.localStorage.getItem("user")).uid
					}).then(resp => {
						//console.log("请求完毕>>", resp);
						if (resp.code === 200) {
							that.selectPassAll();
						}
					}).catch(err => {
						console.log("请求报错>>", err);
					}).finally(() => {
						//console.log("必须执行");
						that.passQuit(); //关闭遮罩
					});
				}
			},
			//获取鼠标位置
			xOrY() {
				var e = event || window.event;
				var scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
				var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
				var x = e.pageX || e.clientX + scrollX;
				var y = e.pageY || e.clientY + scrollY;
				// console.log("鼠标位置>>",'x: ' + x + ',y: ' + y);
				return {
					'x': x,
					'y': y
				};
			},
			log(i) {
				alert(i);
			},
			// 初始化页面主体区域大小方法
			async PassParentInit() {
				let heightBody = document.documentElement.clientHeight;
				//console.log("可视区窗口高度:" + heightBody) // 可视区窗口高度
				let heightNum = document.getElementById("PassParent").offsetTop;
				//console.log("当前元素与页面顶部距离", heightNum) // 当前元素与页面顶部距离
				document.getElementById('PassParent').style.height = (heightBody - heightNum) + "px";
				this.tableHeight = (heightBody - heightNum) + "px";
				this.tableShow = true;
				//console.log("123>>", this.tableHeight);
			},
			//表格后操作按钮
			deleteRow(index, rows) {
				console.log("触发单击事件>>", index, rows);
				//console.log("点击移除>>", index, rows);
				//this.tablePassTag='pass';
				//rows.splice(index, 1);
			},
			// 单元格双击触发事件
			cellClick(row, column, cell, event) {
				console.log("双击", row.account);
				this.tableData.forEach(item => {
					if (item.passString != '*********') {
						item.passString = '*********';
					}
				});
				row.passString = row.pass;

				//老式复制,新版存在兼容性问题
				var textArea = document.createElement("textarea");
				textArea.value = row.account;
				// 将文本区域添加到页面中
				document.body.appendChild(textArea);
				// 选择文本
				textArea.select();
				try {
					// 执行复制命令
					document.execCommand('copy');
					console.log('文本已成功复制到粘贴板');
				} catch (err) {
					console.error('无法复制文本: ', err);
				}
				// 从页面中移除文本区域
				document.body.removeChild(textArea);
				// navigator.clipboard.writeText(row.account)
				// 	.then(() => {
				// 		console.log('账号已成功复制到粘贴板');
				// 	})
				// 	.catch(err => {
				// 		console.error('无法复制文本: ', err);
				// 	});
				//column.property='pass';
			},
			//根据uid查询密码方法		--	条件写死
			async selectPass() {
				return new Promise((resolve, reject) => {
					http.post("/api/pass/getPassByUid", {
						"uid": JSON.parse(window.localStorage.getItem("user")).uid
					}).then(resp => {
						resolve(resp.data);
					}).catch(err => {
						reject(err);
					});
				});
			},
		},
		destroyed() {
			// console.log("销毁调用");
			// 取消浏览器自带的右键菜单
			// window.oncontextmenu = function(e) {
			// 	//取消默认的浏览器自带右键
			// 	e.preventDefault();
			// }
			// 恢复浏览器的右击事件
			window.oncontextmenu = null;
		}
	}
</script>

<style>
	#PassParent {
		position: relative;
		/* background: url('~@/assets/a2.jpeg') no-repeat center; */
		background-color: rgba(100, 100, 100, 0.4);
		background-size: 100% 100%;
		/* z-index: 0; */
	}

	#tableBody {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		/* border: 1px red solid; */
	}

	/* 密码新增卡片 */
	.textPass {
		font-size: 14px;
	}

	.itemPass {
		margin-bottom: 18px;
	}

	.clearfixPass:before,
	.clearfixPass:after {
		display: table;
		content: "";
	}

	.clearfixPass:after {
		clear: both
	}

	.box-cardPass {
		width: 480px;
	}

	/* 密码新增卡片 */

	.text {
		font-size: 14px;
		margin: 1px 0px;
		text-align: left;
		padding-left: 5px;
	}

	.text:hover {
		cursor: pointer;
		color: blue;
	}

	.item {}

	.box-card {
		padding: 0px;
		width: 200px;
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 99999;
	}

	.menu {
		position: absolute;
		top: 0px;
		left: 0px;
		background: #fff;
		border: 1px solid #dadce0;
		visibility: hidden;
	}

	.active {
		visibility: visible;
	}

	.menu__item,
	.submenu__item {
		width: 200px;
		height: 20px;
	}

	.menu__item:hover,
	.submenu__item:hover {
		background-color: #dadce0;
	}

	.menu__item:hover .submenu {
		opacity: 1;
	}

	.submenu {
		position: relative;
		top: -20px;
		left: 200px;
		opacity: 0;
		background: #fff;
		border: 1px solid #dadce0;
	}

	.icon {
		position: absolute;
		right: 3px;
	}
</style>