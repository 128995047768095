import http from '../utils/http.js'


const billService = {
	//查询账单数据信息
	async selectBillData(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/bill/getBillData", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**查询指定时间内日期分类明细报表数据
	 * @param {Object} data
	 */
	async getBillReportFormsDateType(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/bill/getBillReportFormsDateType", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**查询指定时间内日期报表明细数据
	 * @param {Object} data
	 */
	async getBillReportFormsDataDetail(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/bill/getBillReportFormsDateDetail", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	//新增账单数据信息
	async insertBillData(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/bill/insertBill", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	}
	
	
}


export default billService;
