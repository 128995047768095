import http from '../utils/http.js'


const PassService = {
	/**
	 * 查询密码数据信息
	 */
	async selectPassData() {
		return new Promise((resolve, reject) => {
			http.post("/api/pass/getPassByUid", {
				"uid": JSON.parse(window.localStorage.getItem("user")).uid
			}).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**新增密码数据信息
	 * @param {Object} data json参数
	 */
	async insertPassData(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/pass/insertPass", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**删除密码数据信息
	 * @param {Object} data json参数
	 * {
	 *	id: this.deletePassId,
	 *	uid: JSON.parse(window.localStorage.getItem("user")).uid
	 *	}
	 */
	async deletePassData(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/pass/deletePass", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
}


export default PassService;