<template>
	<div>
		<HeaderMenuComponent></HeaderMenuComponent>
		<div id="billTemplate">
			<!-- 账单模块 -->
			<div class="bill-container">

				<!-- 顶部搜索菜单 -->
				<!-- <el-card class="filter-card"> -->
				<!-- <div slot="header" class="card-header"> -->
				<!-- <span>账单列表</span> -->
				<div class="header-controls">
					<div>
						<el-input v-model="searchText" placeholder="搜索关键字" class="search-input" @input="handleSearch">
						</el-input>
						<el-button class="add-button" type="primary" @click="selectButton">查询</el-button>
						<el-button class="add-button" type="primary" @click="addBill">添加账单</el-button>
					</div>
				</div>
				<div style="height: 5px;"></div>
				<!-- </div> -->
				<!-- </el-card> -->

				<!-- 账单主体展示区域 -->
				<div id="billTableBodyId">
					<div>
						<el-table :data="billList" style="width: 100%" :height="tableHeight" border>
							<el-table-column label="日期" width="120">
								<template slot-scope='scope'>
									{{formatDate(scope.row.billTime)}}
								</template>
							</el-table-column>
							<el-table-column label="名称" width="160" prop="title"></el-table-column>
							<el-table-column label="类型" prop="billType" width="120"></el-table-column>
							<el-table-column label="金额" prop="money" width="120"></el-table-column>
							<el-table-column label="标签" width="160">
								<template slot-scope="scope">
									<!-- <el-tag v-for="(tag, index) in scope.row.tags" :key="index" closable
										@close="removeTag(index, scope.row)">
										{{ tag }}
									</el-tag> -->
									<el-tag :key="tag.id" v-for="tag in scope.row.billTags"
										:disable-transitions="false" @close="handleCloseTagInput(scope.row,tag)">
										{{tag.value}}
									</el-tag>
									<el-input class="input-new-tag" v-if="scope.row.inputVisible" v-model="inputValue"
										ref="saveTagInput" size="small"
										@keyup.enter.native="handleInputConfirm(scope.row)"
										@blur="handleInputConfirm(scope.row)">
									</el-input>
									<!-- <el-button v-else class="button-new-tag" size="small" @click="showInput(scope.row)">+ New
										Tag
									</el-button> -->
									<!-- <el-popover placement="top" trigger="click" v-model="editedBill.showTagInput">
										<el-button slot="reference" icon="el-icon-plus">New Tag</el-button>
										<el-input v-model="editedBill.tag" class="tag-input" @keyup.enter="addTag"></el-input>
										<el-button slot="footer" @click="addTag">Add</el-button>
									</el-popover> -->
								</template>
							</el-table-column>

							<el-table-column label="操作" width="160">
								<template slot-scope="scope">
									<el-button size="mini" :disabled='true' @click="editBill(scope.$index, scope.row)">编辑</el-button>
									<el-button size="mini" :disabled='true' type="danger"
										@click="deleteBill(scope.$index)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<!-- 弹出层, 新增修改使用 -->
				<el-dialog :visible.sync="dialogVisible" :closeOnClickModal="false" title="新增账单" @close="handleClose" width="700px">
					<div id="bill_dialog_parentDiv">
						<div id="bill_dialog_parentDiv_leftDiv">
							<el-form :model="editedBill" label-width="80px">
								<el-form-item label="日期" style="text-align: left;">
									<!-- <el-date-picker v-model="editedBill.billTime" type="date"></el-date-picker> -->
									<el-date-picker v-model="editedBill.billTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间">
									</el-date-picker>
								</el-form-item>
								<el-form-item label="名称">
									<el-input v-model="editedBill.title"></el-input>
								</el-form-item>
								<el-form-item label="记账类型" style="text-align: left;">
									<el-select v-model="editedBill.billType" placeholder="请选择记账类型">
										<el-option label="交通出行" value="交通出行"></el-option>
										<el-option label="购物娱乐" value="购物娱乐"></el-option>
										<el-option label="饮食就餐" value="饮食就餐"></el-option>
										<el-option label="物业家居" value="物业家居"></el-option>
										<el-option label="其他类型" value="其他类型"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="标签" style="text-align: left;">
									<el-tag :key="tag.value" v-for="tag in dynamicTags" closable :disable-transitions="false"
										@close="handleCloseOnEdit(tag.value)">
										{{tag.value}}
									</el-tag>
									<el-input class="input-new-tag" v-if="inputVisibleOnEdit" v-model="inputValueOnEdit"
										ref="saveTagEditInput" size="small" @keyup.enter.native="handleInputConfirmOnEdit(1)"
										@blur="handleInputConfirmOnEdit(1)">
									</el-input>
									<el-button v-else class="button-new-tag" size="small" @click="showInputOnEdit">+ New Tag
									</el-button>
								</el-form-item>
								<el-form-item label="金额">
									<el-input v-model="editedBill.money" type="number"></el-input>
								</el-form-item>
								<el-form-item label="备注">
									<el-input v-model="editedBill.remark"></el-input>
								</el-form-item>
							</el-form>
							
						</div>
						<div id="bill_dialog_parentDiv_rightDiv">
							<div>
								<h4>账单模版</h4>
							</div>
							<div style="display: flex; justify-content: center;flex-wrap: wrap; ">
								<el-button style="margin: 0;margin-right: 20px;margin-bottom: 20px;" @click="bill_dislog_template_click(1)">中午快餐</el-button>
								<el-button style="margin: 0;margin-bottom: 20px;" @click="bill_dislog_template_click(2)">淘宝购物</el-button>
								<el-button style="margin: 0;margin-right: 20px;margin-bottom: 20px;" @click="bill_dislog_template_click(3)">京东购物</el-button>
								<el-button style="margin: 0;margin-bottom: 20px;" @click="bill_dislog_template_click(4)">话费充值</el-button>
								<el-button style="margin: 0;" @click="bill_dislog_template_click(5)">公交卡充值</el-button>
							</div>
							<div>
								<h4>快捷时间</h4>
							</div>
							<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
								<el-button style="margin: 0;margin-bottom: 15px;margin-right: 15px;" @click="bill_dislog_time_click(1)">早八点</el-button>
								<el-button style="margin: 0;margin-bottom: 15px;margin-right: 15px" @click="bill_dislog_time_click(2)">晚六点</el-button>
								<el-button style="margin: 0;margin-bottom: 15px;" @click="bill_dislog_time_click(3)">晚八点</el-button>
								<el-button style="margin: 0;margin-bottom: 15px;margin-right: 15px" @click="bill_dislog_time_click(4)">午十一点</el-button>
								<el-button style="margin: 0;margin-bottom: 15px;" @click="bill_dislog_time_click(5)">午十二点</el-button>
							</div>
						</div>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button @click="handleClose">取消</el-button>
						<el-button type="primary" @click="saveBill">保存</el-button>
					</span>
				</el-dialog>
				<!-- <el-button class="add-button" type="primary" @click="addBill">添加账单</el-button> -->
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../utils/http.js'
	import jsUtils from '../utils/jsUtils.js'
	import billService from '../service/billService.js'

	export default {
		name: 'ZhangBen',
		components: {},
		data() {
			return {
				// 表格数据
				billList: [],
				dialogVisible: false, //编辑新增的弹出层显示控制
				editedBill: { //弹出层内实体属性--新增修改使用
					title: '',
					money: '',
					billType: '',
					billTime: '',
					remark: '',
					billTags: [],
					showTagInput: false
				},
				searchText: '', //搜索框属性
				dynamicTags: [], //弹出层中标签的绑定属性
				inputVisible: false, //标签新增的输入框
				inputValue: '',
				inputVisibleOnEdit: false, //弹出层中标签新增的输入框显示与隐藏
				inputValueOnEdit: '', //标签新增中的输入框绑定
				tableHeight: 0, //表格的高度
				editOrSave: -1 //指示弹出层是新增事件还是修改时间--0为新增1为修改-1为默认无状态
			}
		},
		created() {
			// console.log("ZhangBen>触发created");
		},
		async mounted() {
			// console.log("ZhangBen>调用mounted");
			let that = this;

			//获取uid
			let user = jsUtils.getLoginUser();
			if (user != null) {
				this.uid = user.uid;
			}

			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			//初始化页面大小
			await jsUtils.PassParentInitTopHeight("billTemplate");

			//默认查询账单数据
			await this.selectBillAll();

			// 设置表格区域高度
			await jsUtils.elementHeightOnDoc("billTableBodyId").then(resp => {
				this.tableHeight = resp;
			});

			// console.log("初始化完毕!");

			this.$loading().close();
		},
		computed: {
			filteredBillList() {
				// 根据搜索文本过滤账单列表
				return this.billList.filter(bill => {
					const searchLowerCase = this.searchText.toLowerCase();
					return (
						bill.date.toLowerCase().includes(searchLowerCase) ||
						bill.name.toLowerCase().includes(searchLowerCase) ||
						bill.type.toLowerCase().includes(searchLowerCase) ||
						String(bill.amount).includes(searchLowerCase) ||
						bill.tags.some(tag => tag.toLowerCase().includes(searchLowerCase))
					);
				});
			}
		},
		methods: {
			//格式化时间方法
			getFormattedDate(date) {
			  const year = date.getFullYear();
			  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
			  const day = String(date.getDate()).padStart(2, '0');
			  const hours = String(date.getHours()).padStart(2, '0');
			  const minutes = String(date.getMinutes()).padStart(2, '0');
			  const seconds = String(date.getSeconds()).padStart(2, '0');
			  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			},
			// 格式化时间方法
			getTodayAtEightAM(h) {
			  const now = new Date();
			  // 创建当天早上8点的日期对象
			  const todayAtEightAM = new Date(
			    now.getFullYear(),
			    now.getMonth(),
			    now.getDate(),
			    h, 0, 0
			  );
			  return this.getFormattedDate(todayAtEightAM);
			},
			// 账单新增中模版新增事件
			bill_dislog_template_click(type){
				switch (type){
					case 1:
						var time = this.getTodayAtEightAM(12);
						// console.log(time);
						let obj = this.editedBill;
						obj["billTime"] = time;
						obj["title"] = "快餐";
						obj["money"] = 14;
						obj["billType"] = "饮食就餐";
						this.editedBill = {...obj};
						break;
					case 2:
						let obj2 = this.editedBill;
						obj2["title"] = "淘宝购物";
						obj2["billType"] = "购物娱乐";
						this.editedBill = {...obj2};
						break;
					case 3:
						let obj5 = this.editedBill;
						obj5["title"] = "京东购物";
						obj5["billType"] = "购物娱乐";
						this.editedBill = {...obj5};
						break;
					case 4:
						let obj3 = this.editedBill;
						obj3["title"] = "话费充值";
						obj3["money"] = 100;
						obj3["billType"] = "物业家居";
						this.editedBill = {...obj3};
						break;
					case 5:
						let obj4 = this.editedBill;
						obj4["title"] = "公交卡充值";
						obj4["money"] = 50;
						obj4["billType"] = "交通出行";
						this.editedBill = {...obj4};
				}
			},
			//新增账单中时间快捷按钮事件{
			bill_dislog_time_click(type){
				// console.log("触发!!",type);
				switch (type){
					case 1:
						var time = this.getTodayAtEightAM(8);
						break;
					case 2:
						var time = this.getTodayAtEightAM(18);
						break;
					case 3:
						var time = this.getTodayAtEightAM(20);
						break;
					case 4:
						var time = this.getTodayAtEightAM(11);
						break;
					case 5:
						var time = this.getTodayAtEightAM(12);
				}
				let obj = this.editedBill;
				obj["billTime"] = time;
				this.editedBill = {...obj};
				// console.log("55",this.editedBill.billTime);
			},
			//查询账单数据
			async selectBillAll() {
				await billService.selectBillData({
					"queryType": "月"
				}).then(resp => {
					if (resp.code === 200) {
						// console.log("执行完毕>>", resp.data.result);
						this.billList = resp.data.result;
					}
				}).catch(err => {
					console.log("查询账单数据报错>>", err);
				});
			},
			//弹出层中标签的保存功能
			handleInputConfirmOnEdit(row) {
				// console.log('新增前>>',this.editedBill.billTags);
				let inputValue = {
					value: this.inputValueOnEdit
				};
				// console.log("对象为>>",inputValue);
				if (inputValue) {
					this.dynamicTags.push(inputValue);
				}
				this.inputVisibleOnEdit = false;
				this.inputValueOnEdit = '';
				// console.log('新增后>>',this.editedBill.billTags);
			},
			//弹出层中标签的删除按钮事件
			handleCloseOnEdit(tag) {
				this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
			},
			//弹出层中标签的新增按钮事件--用以打开输入框
			showInputOnEdit() {
				this.inputVisibleOnEdit = true;
				this.$nextTick(_ => {
					this.$refs.saveTagEditInput.$refs.input.focus();
				});
			},
			//查询按钮点击事件
			selectButton() {
				console.log('点击了查询按钮!');
			},
			//格式化日期时间的方法
			formatDate(datetime) {
				// const dateObject = new Date(datetime);
				// // 获取年月日部分，创建新的 Date 对象
				// const dateOnly = new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
				// // console.log(dateOnly);
				// // 使用 toLocaleDateString 方法将日期对象转换为本地日期字符串
				// return dateOnly.toLocaleDateString();

				const dateObject = new Date(datetime);
				// Format options for yyyy-MM-dd
				const options = {
					year: 'numeric',
					month: '2-digit',
					day: '2-digit'
				};
				// Use toLocaleDateString with options
				const formattedDate = dateObject.toLocaleDateString(undefined, options);
				// Replace slashes with dashes
				return formattedDate.replace(/\//g, '-');
			},
			//删除标签方法
			handleCloseTagInput(row, tag) {
				console.log("标签>>", '账单行数据>>', row, "标签行数据", tag);
				const copyObj = Object.assign({}, row);
				row.billTags.splice(row.billTags.indexOf(tag), 1);
				// bill.tags.splice(index, 1);
			},
			//显示标签新增输入框方法
			showInput(row) {
				row.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			//标签保存的功能
			handleInputConfirm(row) {
				// console.log("获取当前行>>",row);
				let inputValue = row.inputValue;
				if (inputValue) {
					this.dynamicTags.push(inputValue);
				}
				row.inputVisible = false;
				row.inputValue = '';
			},
			showTagInput() {
				this.editedBill.showTagInput = true;
				this.$nextTick(() => {
					this.$refs.tagInput.focus();
				});
			},

			addTag() {
				const trimmedTag = this.editedBill.tag.trim();
				if (trimmedTag && !this.editedBill.tags.includes(trimmedTag)) {
					this.editedBill.tags.push(trimmedTag);
					this.editedBill.tag = ''; // 清空输入框
					this.editedBill.showTagInput = false; // 隐藏输入框
				}
			},
			addTag() {
				console.log('触发回车新增标签>>');
				const trimmedTag = this.editedBill.tag.trim();
				if (trimmedTag && !this.editedBill.tags.includes(trimmedTag)) {
					this.editedBill.tags.push(trimmedTag);
					this.editedBill.tag = ''; // 清空输入框
				}
			},
			removeTag(index, bill) {
				bill.tags.splice(index, 1);
			},

			handleSearch() {
				// 在这里处理搜索逻辑，如果需要的话
			},
			//新增账单的方法--打开弹出层, 清空弹出层绑定属性
			addBill() {
				this.editedBill = {
					date: '',
					name: '',
					amount: 0
				};
				this.dynamicTags = [];
				this.dialogVisible = true;
			},
			//账单编辑按钮点击事件
			editBill(index, row) {
				this.editedBill = {
					...row
				};
				this.dynamicTags = [
					...row.billTags
				];
				this.$nextTick(() => {
					this.dialogVisible = true;
				});
				// console.log("修改的标签>>",this.dynamicTags);
			},
			//弹出层表单保存按钮--新增账单
			async saveBill() {
				this.$loading({
					title: '新增中'
				});
				this.editedBill["billTags"] = this.dynamicTags;
				// console.log('表单信息为>>', this.editedBill);
				await billService.insertBillData(this.editedBill).then(resp => {
					// console.log("执行新增完毕>>",resp);
					if (resp) {
						if (resp.code === 200) {
							this.dialogVisible = false;
							this.$message({
								message: "新增成功!",
								type: "success",
								duration: 2000,
								showColse: true
							});
							this.selectBillAll(); //重新查询数据
						}
					}
				}).catch(err => {
					console.log('新增报错>>', err);
				}).finally(() => {
					this.$loading().close();
				});
			},
			//删除账单按钮点击事件
			deleteBill(index) {
				this.$confirm('确认删除该账单吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.billList.splice(index, 1);
					this.$message.success('删除成功');
				}).catch(() => {
					// Do nothing if canceled
				});
			},
			//关闭弹出层方法
			handleClose() {
				this.dialogVisible = false;
			}
		}
	}
</script>

<style>
	/* 新增账单中主体的父级div */
	#bill_dialog_parentDiv{
		display: flex;
		justify-content: center; /* 水平居中对齐 */
	}
	#bill_dialog_parentDiv_leftDiv{
		/* display: inline-block; */
		width: 300px;
	}
	#bill_dialog_parentDiv_rightDiv{
		/* display: inline-block; */
		width: 300px;
		margin-left: 10px;
	}
	
	#billTableBodyId {
		display: flex;
		justify-content: center;
	}

	#billTableBodyId>div {
		width: 850px;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}

	.tag-input {
		width: 100px;
	}

	.tag-input {
		width: 80px;
		margin-left: 5px;
	}

	.el-card__body {
		/* padding: 0px; */
	}

	.bill-container {
		margin: 20px;
	}

	.filter-card {
		/* margin-top: 20px; */
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		overflow: hidden;
	}

	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 16px;
		padding-left: 20px 10px;
		background-color: #f5f7fa;
	}

	.header-controls {
		display: flex;
		justify-content: center;
	}
	.header-controls>div {
		width: 850px;
	}

	.search-input {
		width: 150px;
		margin-right: 20px;
		font-size: 14px;
	}

	.add-button {
		font-size: 14px;
	}
</style>