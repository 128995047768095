import http from '../utils/http.js'


const MessageTreeNew = {
	//查询账单摘要数据信息
	async selectMessageTreeNew(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageNew/selectAllMessageTreeNew", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	
}


export default MessageTreeNew;
