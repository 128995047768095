<template>
	<div ref="messageTreeBody" v-loading.fullscreen.lock="fullscreenLoading">
		<HeaderMenuComponent></HeaderMenuComponent>
		<!-- 画布 -->
		<div id="messageTreeParent" @click='messageTreeParentClick'>
			<canvas id="canvasByMessageTree" style="display: none;"></canvas>
			<div id="messageTreeChild" class="page-view">
				<!-- 笔记头部的搜索筛选功能框 -->
				<div id="searchBody">
					<div style="margin-left: 10px;margin-right: 10px; width: 250px;">
						<el-input placeholder="输入笔记标题搜索" v-model="searchInput" class="input-with-select" clearable>
							<el-button slot="append" @click="searchClick" icon="el-icon-search"></el-button>
						</el-input>
					</div>
					<div>
						<el-button type="primary" @click="insertMessageClickByDiv">添加笔记</el-button>
					</div>
				</div>
				<div id="messageTreeBody">
					<!-- 笔记标题弹出层 -->
					<el-popover placement="top" width="180" v-for="(item,index) in listData" :key="item.id"
						trigger="manual" v-model="popoverVisibles[index]">
						<p>要对这个笔记做什么操作？</p>
						<div style="text-align: right; margin: 0">
							<el-button size="mini" type="danger" @click="deleteMessage(item.id)">删除</el-button>
							<el-button type="primary" size="mini" @click="updateMessage(item.id)">调整</el-button>
							<!-- <el-button type="primary" size="mini" >编辑笔记</el-button> -->
						</div>
						<!-- 笔记标题区域 -->
						<div class="messageTree" :rtx='item.id' slot="reference" @click="messageTreeClick(item.id)">
							<div class="messageTreeImage">
								<input width="40px" height="40px" type="image" :src="item.messImage">
							</div>
							<div class="messageTreeText">
								{{item.title}}
							</div>
						</div>
					</el-popover>
				</div>
			</div>
			<!-- 遮罩层, 弃用 -->
			<div id="loading"></div>

			<!-- 调整笔记标题的弹出层 -->
			<div id="cardDivUpdateOnMessageTree">
				<el-card class="box-cardMessage" shadow="always">
					<!-- <el-form label-position="right" label-width="80px" :model="formLabelAlign" size="mini">
						<el-form-item label="笔记标题">
							<el-input v-model="formLabelAlign.title"></el-input>
						</el-form-item>
						<el-form-item label="笔记备注">
							<el-input v-model="formLabelAlign.bz"></el-input>
						</el-form-item>
						<el-form-item label="笔记次序">
							<el-input-number ref="selectInput" v-model="formLabelAlign.orderId" :min="1" :max="10"
								label="笔记层级">
							</el-input-number>
						</el-form-item>
					</el-form> -->
					<el-button size="small" style="margin-top: 10px;" type="info" @click="messageEdit" round>编辑笔记
					</el-button>
					<el-button size="small" style="margin-top: 10px;margin-left: 30px;" type="primary" round>保存
					</el-button>
					<el-button size="small" style="margin-top: 10px;margin-left: 30px;" type="primary"
						@click="downClickOnMessageTree" round>取消</el-button>
				</el-card>
			</div>

			<!-- 右键菜单, element卡片 -->
			<el-card class="box-card" ref="mouseMessageTree">
				<div class="text item" @click="mouseClick('insert')">
					新增笔记信息
				</div>
				<div class="text item" @click="mouseClick('delete')">
					-----
				</div>
				<div class="text item" @click="mouseClick('quit')">
					关闭菜单
				</div>
			</el-card>

			<!-- 修改笔记标题内容弹出层 -->
			<div id="messageTreeUpdateDivParent">
				<el-card class="box-cardMessage">
					<!-- <h4>笔记标题</h4> -->
					<el-input v-model="updateData.title" placeholder="请输入标题">
						<template slot="prepend">标题</template>
					</el-input>
					<el-input v-model="updateData.bz" placeholder="请输入备注" style="margin-top: 10px;">
						<template slot="prepend">备注</template>
					</el-input>
					<el-input v-model="updateData.messImage" placeholder="暂无图片" style="margin-top: 10px;"
						:disabled="true">
						<template slot="prepend">笔记图片</template>
					</el-input>
					<div style="margin-top: 10px;">
						<el-upload action="/api/images/uploadMessageTreeImageOne" list-type="picture-card" name='uploadFile'
							:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-success='uploadImage'
							:headers='uploadHeaders' :multiple='false' :limit='1'>
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
					<el-button style="margin-top: 10px;" type="primary" @click="updateMessageData" round>修改</el-button>
					<el-button style="margin-top: 10px;" type="primary" @click="downClick" round>取消</el-button>
				</el-card>
			</div>

			<!-- 新增笔记标题的弹出层 -->
			<div id="cardDiv">
				<el-card class="box-cardMessage" shadow="always">
					<!-- <h4>笔记标题</h4> -->
					<el-input v-model="input" placeholder="请输入标题"></el-input>
					<el-button style="margin-top: 10px;" type="primary" @click="insertMessage" round>新增</el-button>
					<el-button style="margin-top: 10px;" type="primary" @click="downClick" round>取消</el-button>
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	import jsUtils from '../utils/jsUtils.js'
	import http from '../utils/http.js'
	import MessageTreeService from '../service/MessageTree.js'
	import messageTreeService from '../service/MessageTree.js'
	import messageService from '../service/Message.js'

	export default {
		name: 'messageTree',
		data() {
			return {
				listData: [], //一级标题数据集合
				listDataBack: [], //一级标题数据集合的备份
				deletePassId: -1, //删除标志
				input: "", //新增中的笔记标题
				fullscreenLoading: false, //全屏遮罩打开与否
				uid: -1,
				updateMessageTreeId: 0, //调整笔记标题id
				searchInput: '', //笔记标题顶部筛选框中的搜索输入框数据
				searchClickTime: null, //搜索框搜索按钮定时器
				messageTreeParentHeight: 0, //父级元素高度
				messageTreeParentWidth: 0, //父级元素宽度
				popoverVisibles: [], //笔记一级标题的弹出窗口展示
				popoverVisiblesBak: [], //笔记一级标题的弹出窗口展示--备份还原用
				popoverTimeOut: null, //定时器, 用于清除小窗口的事件
				messageTreeUpdateDivIsFlag: false, //笔记标题修改弹出层是否显示
				updateData: {}, //修改用目标数据
				dialogImageUrl: '',
				dialogVisible: false,
				uploadHeaders: {}, //文件上传标头
			}
		},
		components: {},
		created() {
			// console.log("触发created");
		},
		async mounted() {

			let that = this;
			// console.log("进入messageTree");

			//获取uid
			let user = jsUtils.getLoginUser();
			if (user != null) {
				this.uid = user.uid;
			}

			this.$loading({
				lock: true,
				text: '加载中',
				//spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			// 隐藏卡片弹出层
			document.getElementById("cardDiv").style = "display:none;";

			//文件上传标头
			this.uploadHeaders = {
				'jwt': window.localStorage.getItem('jwt')
			}

			// console.log("调用mounted");
			// 鼠标事件
			/* document.getElementById("messageTreeBody").onmousedown = function(e) {
				let element = e.srcElement;
				// console.log("事件>>", e, element);
				if (e.button == 2) {
					// console.log("你点了右键");
					//获取鼠标位置
					let xy = jsUtils.mouseXOrY(event);
					// console.log("右击鼠标", xy);
					let cardStyle = "position: fixed;left: " + xy.x + "px;top: " + xy.y + "px;";
					//console.log("样式:", cardStyle);
					that.$refs.mouseMessageTree.$el.style = cardStyle;
					// console.log(that.$refs.mouseMessageTree.$el);
				} else if (e.button == 0) {
					// console.log("你点了左键");
				} else if (e.button == 1) {
					// console.log("你点了滚轮");
				}
			} */

			//初始化右键菜单
			let card = this.$refs.mouseMessageTree;
			card.$el.style = "display:none;";
			card.$el.getElementsByTagName("div")[0].style = "margin: 0;padding: 2px;";
			// this.$refs.insertPassRef.$el.style = "display:none;";

			//初始化页面大小
			await jsUtils.PassParentInitTopHeight("messageTreeParent");
			await jsUtils.PassParentInitTopHeightOrsearchBody("messageTreeBody");
			// console.log("abc",document.getElementById("messageTreeParent").clientHeight);
			// console.log("abc",document.getElementById("messageTreeParent").clientWidth);
			this.messageTreeParentWidth = document.getElementById("messageTreeParent").clientWidth;
			this.messageTreeParentHeight = document.getElementById("messageTreeParent").clientHeight;
			document.getElementById("canvasByMessageTree").style.width = this.messageTreeParentWidth + 'px';
			document.getElementById("canvasByMessageTree").style.height = this.messageTreeParentHeight - 4 + 'px';
			//画布效果启用
			this.canvasBy();
			//初始化查询一级标题
			await this.selectByMessageTree();

			jsUtils.loadingAllIsNone(true, "loadingAll"); //隐藏大遮罩

			await this.$loading().close();
		},
		methods: {
			//修改调用方法
			async updateMessageData(){
				this.$loading({
					text: '加载中',
				});
				await MessageTreeService.udpateMessageTreeByTid(this.updateData).then(resp=>{
					// console.log("修改结果>>",resp);
					if(resp.code==200){
						this.$message({
							message: "修改成功!",
							type: 'success',
							duration: 2000,
							showClose: true
						});
					}
				}).catch(err=>{
					console.log("修改失败>>",err);
				});
				
				await this.selectByMessageTree();
				document.getElementById("messageTreeUpdateDivParent").style = "display: none;";
				this.fullscreenLoading = false;
				this.$loading().close();
			},
			// 上传笔记标题图片成功回调
			uploadImage(response, file, fileList) {
				// console.log("上传成功!",response, file, fileList);
				if (response.code == 200) {
					this.updateData.messImage = response.data.result;
				}
			},
			handleRemove(file, fileList) {
				console.log("上传>>", file, fileList);
			},
			handlePictureCardPreview(file) {
				console.log("file>>", file);
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			//标题中的调整事件
			updateMessage(id) {
				// console.log("点击了调整>>", id);
				// this.messageTreeUpdateDivIsFlag=true;
				this.fullscreenLoading = true;
				document.getElementById("messageTreeUpdateDivParent").style = "display:block;";
				// console.log("集合>>",this.listData);
				// console.log("上传标头>>",this.uploadHeaders);

				this.listData.forEach(item => {
					if (item.id == id) {
						// console.log("目标集合>>", item);
						this.updateData = {
							...item
						};
					}
				});
			},
			//新增笔记按钮点击事件
			insertMessageClickByDiv() {
				this.fullscreenLoading = true;
				// 显示卡片弹出层
				document.getElementById("cardDiv").style = "display:block;";
			},
			//父级div点击事件
			messageTreeParentClick() {
				// console.log('1');
				this.popoverVisibles = [...this.popoverVisiblesBak];
			},
			// 画布效果
			canvasBy() {
				let that = this;
				var mcanvas = document.getElementsByTagName("canvas")[0];
				var ctx = mcanvas.getContext("2d");
				var mw = mcanvas.width;
				var mh = mcanvas.height;
				// console.log('1-',mw);
				// console.log('2-',mh);
				var str = "0123456789abcdefghijklmnopqrstuvwxyz";
				var fontSize = 6;
				var wCount = mw / fontSize;
				var hCount = mh / fontSize;
				// console.log('3-',wCount);
				// console.log('4-',hCount);
				//    var index=0;
				var content = [];
				for (var a = 0; a < wCount; a++) {
					content[a] = 0;
				}
				var timer = setInterval(function() {
					ctx.fillStyle = "rgba(0,0,0,0.1)";
					// ctx.fillStyle = "rgba(118, 118, 118, 0.1)";
					ctx.fillRect(0, 0, that.messageTreeParentHeight, that.messageTreeParentHeight);
					// console.log('5-',ctx.fillRect);
					for (var i = 0; i < wCount; i++) {
						// ctx.font = "fontSize '微软雅黑'";
						ctx.font = `5px Microsoft YaHei`
						ctx.textBaseline = "top";
						ctx.textAlign = "center";
						ctx.fillStyle = "#ccc";
						// ctx.fillStyle = "#24aa00";
						var suiji = Math.random() * str.length;
						ctx.fillText(str[Math.floor(suiji)], fontSize * i, content[i] * fontSize);
						content[i]++;
						if (content[i] * fontSize > mh || Math.random() < 0.1) {
							content[i] = 0;
						}
					}
				}, 100);
			},
			// 搜索框的搜索按钮单击事件
			searchClick() {
				// console.log(this.searchClickTime);
				//防抖实现, 定时器存在, 清除定时器任务
				if (this.searchClickTime) {
					clearTimeout(this.searchClickTime);
				}
				this.searchClickTime = window.setTimeout(() => {
					this.listDataBack = this.listData;
					console.log("搜索框点击搜索!", this.searchInput, this.listDataBack);

				}, 500);
			},
			//编辑笔记按钮
			async messageEdit() {
				this.$router.push({
					path: "/message",
					query: {
						tid: this.updateMessageTreeId
					}
				});
			},
			//调整弹出层中的  取消按钮
			async downClickOnMessageTree() {
				// console.log("取消了调整");
				this.fullscreenLoading = false;
				// this.input = "";
				document.getElementById("cardDiv").style = "display: none;";
				document.getElementById("cardDivUpdateOnMessageTree").style = "display: none;";
				//隐藏工具条
				this.$refs.messageTreeBody.classList.remove("messageBodyClass");
				//修改调整状态
				// this.updateLogo = false;
				//修改新增状态
				// this.insertLogo = false;
			},
			//标题中的删除事件
			async deleteMessage(id) {
				console.log("点击了删除>>", id);
				this.fullscreenLoading = true;
				
				//重置弹出等状态
				this.popoverVisibles = [...this.popoverVisiblesBak];

				await this.$confirm('是否确认删除笔记?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					messageService.deleteMessageByTid({
						id: id
					}).then(resp => {
						// console.log("执行完毕>>", resp);
						if (resp.code === 200) {
							this.$message({
								message: "删除成功!",
								type: 'success',
								duration: 2000,
								showClose: true
							});
							this.selectByMessageTree();
						}
						// console.log("执行完毕>>", this.messageData);
					}).catch(err => {
						console.log("执行出错>>", err);
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});

				this.fullscreenLoading = false;
			},
			// 标题点击事件
			messageTreeClick(id) {
				/* console.log("点击了标题>>", id);
				let routeUrl = this.$router.resolve({
					path: "/messageShow",
					query: {
						tid: id
					}
				});
				// console.log("跳转前>>", routeUrl, routeUrl.route);
				window.open(routeUrl.href, '_blank') //关键在此 */

				//直接进入编辑--不修改背景图等功能
				this.updateMessageTreeId = id;
				this.$router.push({
					path: "/message",
					query: {
						tid: this.updateMessageTreeId
					}
				});
			},
			//右键菜单单击事件
			async mouseClick(str) {
				let that = this;
				//console.log("单击菜单>>",str);
				this.$refs.mouseMessageTree.$el.style = "display: none;"; //关闭右键菜单
				if (str == "insert") {
					this.fullscreenLoading = true;
					// 显示卡片弹出层
					document.getElementById("cardDiv").style = "display:block;";
				}
				if (str == "quit") {
					//console.log("退出菜单");
					this.passQuit();
					this.deletePassId = -1; //删除上传标识
				}
				if (str == 'delete') {
					this.passQuit();
				}
			},
			// 新增笔记
			async insertMessage() {
				if (this.input == "") {
					this.input = "新增的笔记";
				}
				await messageTreeService.insertMessageTreeDataByTid({
					title: this.input,
					parentid: null,
					uid: this.uid,
					allShow: 0
				}).then(resp => {
					// console.log("新增结果>>", resp);
					if (resp.code === 200) {
						this.$message({
							message: "新增成功!",
							type: 'success',
							duration: 2000,
							showClose: true
						});
						this.input = "";
						document.getElementById("cardDiv").style = "display: none;";
					}
				}).catch(err => {
					console.log("新增报错>>", err);
				});

				await this.selectByMessageTree();

				this.fullscreenLoading = false;
				// this.$loading().close();
			},
			//取消新增按钮
			async downClick() {
				this.fullscreenLoading = false;
				this.input = "";
				document.getElementById("cardDiv").style = "display: none;";
				document.getElementById("messageTreeUpdateDivParent").style = "display: none;";
			},
			//新增密码业务退出按钮
			async passQuit() {
				console.log(触发);
				// await this.loadingBody.close();
				// this.loadingBody = null;
				// this.$loading().close();
				// this.fullscreenLoading = false;
				// this.$refs.insertPassRef.$el.style = "display:none;";
				//console.log("样式>>",this.$refs.insertPassRef.$el.style);
			},
			// 查询当前登录用户的所有一级未删除的标题
			async selectByMessageTree() {
				let user = jsUtils.getLoginUser();
				let uid = 0;
				let that = this;
				if (user != null) {
					uid = user.uid;
				}
				await MessageTreeService.getMessageTreeByUid(uid).then(resp => {
					// console.log("返回结果>>", resp.data.result);
					if (resp.code === 200) {
						that.listData = resp.data.result;
						//添加弹出小窗口的显示控制属性
						for (var i = 0; i < that.listData.length; i++) {
							this.popoverVisibles.push(false);
						}
						this.popoverVisiblesBak = [...this.popoverVisibles];
					}
				}).catch(err => {
					console.log("执行报错>>", err);
				});
				
				//给元素绑定鼠标事件
				let divList = document.getElementsByClassName('messageTree');
				// console.log("divlist>>",divList,divList[0].getAttribute('rtx'));
				Array.from(divList).forEach((item, index) => {
					// console.log(item,index);
					//触发元素的rtx值, 笔记的id
					let trx = item.getAttribute('rtx');
					let that = this;
					item.addEventListener('contextmenu', function(event) {
						event.preventDefault(); // 阻止默认的右键菜单
						// console.log('鼠标右键点击了元素', trx,that.popoverVisible);
						// that.isShow=true;
						// that.popoverVisibles[index]=true;
						// 创建一个新的数组
						that.popoverVisibles = [...that.popoverVisiblesBak];
						that.popoverVisibles[index] = true;
						// 创建一个只调用一次的计时器，3秒后执行回调函数
				
						clearTimeout(that.popoverTimeOut);
						// console.log('触发2');
						//5秒自动取消小窗口事件
						that.popoverTimeOut = setTimeout(function() {
							that.popoverVisibles = [...that.popoverVisiblesBak];
						}, 5000); // 3000毫秒 = 3秒
				
				
						// console.log(event,that.popoverVisibles);
					});
				});
			},
		}
	}
</script>

<style>
	/* 笔记标题存放div */
	.messageTree {
		width: 370px;
		height: 50px;
		border: 1px solid rgba(100, 100, 100, 0.5);
		border-radius: 7px;
		/* margin-left: 10px; */
		/* margin-right: 10px; */
		margin-top: 20px;
		display: flex;
		align-items: center;
	}

	/* 笔记标题鼠标悬浮效果 */
	.messageTree:hover {
		border: 1px #409eff solid;
		cursor: pointer;
	}

	/* 笔记标题中的照片区域 */
	.messageTreeImage {
		display: inline-block;
		width: 40px;
		height: 40px;
		margin-left: 7px;
		/* background-color: red; */
		/* background: url('~@/assets/a2.jpeg') no-repeat center; */
		border-radius: 7px 7px 7px 7px;
		background-size: 100% 100%;
	}

	/* 笔记标题中的文本区域 */
	.messageTreeText {
		display: inline-block;
		width: 300px;
		height: 50px;
		/* background-color: blue; */
		text-align: left;
		line-height: 50px;
		padding-left: 10px;
		color: black;
	}


	/* 画布属性 */
	#canvasByMessageTree {
		/* border: 1px solid #000; */
	}

	/* 笔记标题的搜索栏 */
	#searchBody {
		height: 60px;
		display: flex;
		justify-content: left;
		align-items: center;
		/* border: 1px solid red; */
	}

	/* 调整笔记卡片的弹出层 */
	#cardDivUpdateOnMessageTree {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: auto;
		transform: translate(-50%, -50%);
		z-index: 9999;
		display: none;
	}

	/* 笔记标题修改卡片父级div样式 */
	#messageTreeUpdateDivParent {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		/* background-color: #f0f0f0; */
		/* padding: 20px; */
		/* border: 1px solid #ccc; */
		z-index: 9999;
		display: none;
	}

	.box-cardMessage {
		width: 480px;
		margin: 10px;
	}

	/* 工具条隐藏的样式 */
	.messageBodyClass {
		overflow: hidden;
	}

	/* 右键菜单 */
	.page-view {
		margin: 0 auto;
		width: 90%;
		height: calc(100vh - 30px);
		background-color: azure;
	}

	/* 遮罩层 */
	.contextmenu-mask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		/* background-color: #000; */
		/* opacity: .2; */
		z-index: 45;
	}

	/* 菜单内容的容器 */
	.contextmenu-content {
		position: fixed;
		left: 999999px;
		top: 999999px;
		z-index: 50;
		user-select: none;
	}

	/* 例子使用内容 */
	.list {
		border: 1px solid #555;
		border-radius: 4px;
		min-width: 180px;
		overflow: hidden;
		/* 处理圆角 */
	}

	.item {
		box-sizing: border-box;
		padding: 0 5px;
		height: 30px;
		line-height: 30px;
		word-break: keep-all;
		/* 很重要，否则会换行 */
		background-color: #fff;
		cursor: default;
	}

	.item:hover {
		background-color: dodgerblue;
		color: #fff;
	}

	/* 右键菜单 */


	/* 组件父级 */
	#messageTreeParent {
		position: relative;
		/* background: url('~@/assets/a2.jpeg') no-repeat center; */
		/* background-size: 100% 100%; */
		/* background-color: rgba(100, 100, 100, 0.4); */
	}

	/* 父级下唯一div */
	#messageTreeChild {
		position: absolute;
		width: 1250px;
		height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: rgba(255, 255, 255, 1);
		/* border: 1px solid red; */
		/* padding-top: 20px; */
	}

	/* 笔记标题存放主体 */
	#messageTreeBody {
		width: 100%;
		/* height: 100%; */
		position: fixed;
		/* display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		overflow: auto; */

		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		overflow: auto;
		align-content: flex-start;
	}


	/* 遮罩层 */
	#loading {
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: rgba(0, 100, 100, 0.5);
		display: none;
	}
</style>