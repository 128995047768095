import http from '../utils/http.js'

const messageTreeService = {

	/**	当前用户所有一级未删除的标题
	 * @param {Object}  uid当前登录用户的uid
	 */
	async getMessageTreeByUid(uid) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/getMessageTreeByUid", {
				uid: uid
			}).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	查询指定tid, uid的笔记标题树形菜单
	 * @param {Object} data	tid以及uid的对象
	 */
	async getMessageTreeDataByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/getMessageTreeByTidIsAll", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	新增笔记标题
	 * @param {Object} data	tid,标题以及uid的对象
	 */
	async insertMessageTreeDataByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/savaMessageTree", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	调整笔记标题
	 * @param {Object} data	标题对象
	 */
	async udpateMessageTreeDataByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/updateMessageTreeOrderId", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	修改笔记标题(一级标题)
	 * @param {Object} data	标题对象
	 */
	async udpateMessageTreeByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/messageTree/updateMessageTreeById", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	

}

export default messageTreeService;
